import {FC, memo, useEffect, useRef, useState} from "react";
import styles from "pages/PosTerminalPage/DropdownSelect.module.scss";
import vector from "app/assets/icons/vector.svg";
import {CRYPTO_SHORT_NAME, MINIMUM_TRANSACTION_AMOUNT} from "shared/utils/currency";

type DropdownSelectProps = {
    openSelect: boolean,
    selected: { text: string, img: string }
    handleToggleClick: () => void
    handleSelectedClick: (text: string, img: string, code: string) => void
    search: string;
    setSearch: (text: string) => void
    coins: any
    dataUser: any
    value: string;
    setErrorInput: (error: string) => void
    error: string;
    setServerError:any
}


export const DropdownSelect: FC = memo((props: DropdownSelectProps) => {

    const {openSelect, handleToggleClick, selected, handleSelectedClick, search, setSearch, coins, dataUser,value,setErrorInput,error,setServerError} = props

    const [filteredOptions, setFilteredOptions] = useState(coins)
    const inputRef = useRef<HTMLInputElement>()

    const handleChangedOption = (text, img, code) => {
        setServerError("")
        handleToggleClick();
        handleSelectedClick(text, img, code)
        if (error) {
            setErrorInput("")
            // if (value < MINIMUM_TRANSACTION_AMOUNT[code]) {
            //     // setErrorInput(`min value ${MINIMUM_TRANSACTION_AMOUNT[code]} ${CRYPTO_SHORT_NAME[code].toLowerCase()}`)
            //     return
            // } else {
            //     setErrorInput("")
            // }
        }
    }

    const filteredList = (searchText, data) => {
        if (!searchText) {
            return data
        } else {
            return data.filter(coin => coin.fullValue.toLowerCase().includes(searchText.toLowerCase()))
        }
    }

    useEffect(() => {
        const debounce = setTimeout(() => {
            const filtered = filteredList(search, coins)
            setFilteredOptions(filtered)
        }, 100)
        return () => clearTimeout(debounce)
    }, [search, coins])

    const code = dataUser.code?.length === 1
    const cursorBlock = dataUser.code && dataUser.code?.length === 1

    return (
        <div className={styles.dropdown}>
            <div className={`${styles.dropdown__currency} ${cursorBlock ? styles.dropdown__none : ''}`} onClick={handleToggleClick}>
                <p className={styles.dropdown__choosed}>
                    {selected.text ? <>
                            <img className={styles.dropdown__changed} src={selected.img} alt=""/>
                            <span className={styles.dropdown__coin}>{`${selected.text}`}</span>
                        </> :
                        <span className={styles.dropdown__choose}>choose currency</span>}
                </p>
                {!code &&
                <button className={`${styles.dropdown__button} ${openSelect ? styles.dropdown__rotated : ''}`}>
                    <img src={vector} alt=""/>
                </button>
                }
            </div>
            <div className={`${styles.dropdown__content} ${openSelect ? styles.dropdown__open : ''}`}>
                <p className={styles.dropdown__change}>Choose currency</p>
                <input className={styles.dropdown__input} ref={inputRef} type="text" placeholder={"Search"}
                       value={search}
                       onChange={(e) => setSearch(e.target.value)}/>
                {filteredOptions.map((coin) => <div key={coin.code}
                                                    className={`${styles.dropdown__item} ${selected.text === coin.fullValue ? styles.dropdown__light : ''}`}
                                                    onClick={() => handleChangedOption(coin.fullValue, coin.img, coin.code)}>
                        <img className={styles.dropdown__img} src={coin.img} alt=""/>
                        <div className={styles.dropdown__value}>{coin.fullValue}</div>
                    </div>
                )}
            </div>
        </div>
    )
})
