import {memo, useEffect, useState} from "react";
import styles from "pages/PosTerminalPage/Tooltip.module.scss";
import tooltip from "app/assets/icons/tooltip.svg";
import {useWindowResize} from "shared/utils/useWindowResize";

type TooltipType = {
    checkBox: boolean
}

export const Tooltip = memo(({checkBox}: TooltipType) => {

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
            if (checkBox) {
                setIsVisible(true)
            }
        }, [checkBox]
    )

    useEffect(() => {
            let timeout = setTimeout(() => setIsVisible(false), 3000)
            return () => clearTimeout(timeout);
        }, [isVisible]
    )

    const size = useWindowResize()
    const direction = size.width < 710 && true

    return (
        <div className={styles.tooltip} onMouseEnter={() => setIsVisible(true)}
             onMouseLeave={() => setIsVisible(false)}>
            <img className={styles.tooltip__img} src={tooltip} alt=""/>
            {isVisible &&
            <p className={`${styles.tooltip__text} ${direction ? styles.tooltip__direction : ''}`}>You will be input amount at US dollar, but payment will be at chosen
                crypto currency. </p>}
        </div>
    )
})
