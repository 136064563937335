import React, { useEffect, useRef, useState } from 'react';
import { OnrampWebSDK } from 'https://cdn.skypack.dev/@onramp.money/onramp-web-sdk';
import { baseUrl } from 'shared/api/url';
// import { OnrampWebSDK } from '@onramp.money/onramp-web-sdk';
window.OnrampWebSDK = OnrampWebSDK;

type OnRampComponentProps = {
  onRampMoneyLink: string;
  openedAccordions: string[];
  handleSetOpenedAccordions: (accordion: string) => void;
};

export const OnRampComponent = (props: OnRampComponentProps) => {
  const [onRampMoney, setOnRampMoney] = useState('');
  // const [isOpen, setIsOpen] = useState(false)
  const isOpen = useRef(false);
  const { onRampMoneyLink, openedAccordions, handleSetOpenedAccordions } = props;
  // const onrampInstance = useRef(null)
  // console.log(onRampMoneyLink)
  // const onRampLink = ''
  useEffect(() => {
    if (onRampMoneyLink) {
      fetch(onRampMoneyLink)
        .then((res) => res.json())
        .then(setOnRampMoney)
        .catch((error) => {
          console.error('Error', error);
        });
    }
  }, [onRampMoneyLink]);
  // console.log(onRampMoney)
  useEffect(() => {
    if (!window.OnrampWebSDK) {
      return;
    }
    if (window.OnrampWebSDK && onRampMoney) {
      // if (!onrampInstance.current) {
      //     let instance = new OnrampWebSDK(onRampMoney);
      //     onrampInstance.current = instance
      //     instance.show()
      // }
      const onrampInstance = new OnrampWebSDK(onRampMoney);
      // console.log(onrampInstance)
      onrampInstance.show();
      isOpen.current = true;
      // setIsOpen(true)
      //"ONRAMP_WIDGET_CLOSE_REQUEST_CONFIRMED"
      onrampInstance.on('WIDGET_EVENTS', (event) => {
        if (event.type === 'ONRAMP_WIDGET_CLOSE_REQUEST_CONFIRMED') {
          // console.log(isOpen.current)
          isOpen.current = false;
          handleSetOpenedAccordions('1');
          onrampInstance.close();
        }
      });
      const bodyDiv = document.querySelector('body > :last-child');
      const shadowRootModal = bodyDiv?.shadowRoot;
      const overlayElement = shadowRootModal?.querySelector('.overlay');
      const iframeElement = overlayElement?.querySelector('iframe');
      // const cardContainer = document.querySelector('.card-container')
      // console.log(cardContainer)
      if (openedAccordions.includes('4')) {
        if (bodyDiv && shadowRootModal && iframeElement) {
          bodyDiv.style.height = '600px';
          bodyDiv.style.width = '400px';
          overlayElement.style.width = '400px';
          overlayElement.style.height = '600px';
          overlayElement.style.position = 'static';
          iframeElement.style.width = '400px';
          iframeElement.style.position = 'static';
        }
      } else {
        if (bodyDiv && shadowRootModal && iframeElement) {
          bodyDiv.style.height = '1px';
          overlayElement.style.height = '1px';
          iframeElement.style.height = '1px';
        }
      }
      const rootForRamp = document.querySelector('#showOnramp');
      // console.log(rootForRamp)
      if (rootForRamp) {
        rootForRamp.appendChild(bodyDiv);
        // const divClose = document.createElement('div')
        // rootForRamp.appendChild(divClose)
        // divClose.style.position = 'absolute'
        // divClose.style.zIndex = '9999'
        // // divClose.style.right = '0'
        // divClose.style.backgroundColor = 'red'
        // divClose.style.width = '40px'
        // divClose.style.height = '40px'
      }
      // console.log(onrampInstance)
      return () => {
        // console.log(isOpen.current)
        if (isOpen.current) {
          onrampInstance.close();
        }
      };
      // return () => {
      //     setId('1')
      //     onrampInstance.close()
      // }
    }
    // if(onrampInstance.close()){
    //
    // }
  }, [onRampMoney, openedAccordions]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          width: '100%',
          overflow: 'scroll',
          margin: '0 auto'
          // position: 'relative'
        }}
        id="showOnramp"
      ></div>
    </div>
  );
};
