import {FC, memo} from "react";
import styles from "pages/InvoicePage/Time.module.scss";
import timer from "app/assets/icons/timer.svg";
import {useTimer} from "shared/utils/time";

export const Time: FC = memo(() => {
    const time = useTimer()

    return (
        <div className={styles.time}>
            <span className={styles.time__link}>Link is valid</span>
            <img className={styles.time__img} src={timer} alt=""/>
            <span className={styles.time__date}>{`${time.hours}:${time.minutes}:${time.seconds}`}</span>
        </div>
    )
})
