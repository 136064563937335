import {ProcessingResponce} from 'shared/utils/currency';
import {paymentLinkAPI} from 'shared/api/api';

export type PathName = 'error' | 'invoice' | 'payment' | 'posterminal' | 'loading';

export const PATH_NAME: Record<PathName, string> = {
    error: 'error',
    invoice: 'invoice',
    payment: 'payment',
    posterminal: 'posterminal',
    loading: 'loading'
};

export type TransactionsStatus = {
    [key: string]: string;
};

export enum TRANSACTIONS_STATUSES {
    PENDING = 'transaction_status_pending',
    WAITING = 'transaction_status_waiting',
    CONFIRMED = 'transaction_status_confirmed',
    PROCESSING = 'transaction_status_processing',
    PROCESSED = 'transaction_status_processed',
    FAILED = 'transaction_status_failed',
    DUST = 'transaction_status_dust',
    EXPIRED = 'transaction_status_expired'
}


const checkExpTime = (decodingPath, setError, setTypePage) => {
    let date = new Date();
    const seconds = date.getTime() / 1000; //86400 1day
    if (decodingPath.exp < seconds) {
        setError('payment link expired');
        setTypePage('error');
        return 'error';
    }
};

const createUserData = (id, code, amount, type) => {
    return {
        txId: id,
        code: code,
        amount: amount,
        type: type
    };
};

const generateFlow = async (invoiceRequest, setTypePage, setCurentCurrency, pathname, setDataUser) => {
    if (!invoiceRequest.amount && !invoiceRequest.code && !invoiceRequest.fiat_amount) {
        // console.log('not amount not code not fiat');
        setTypePage(PATH_NAME.posterminal);
    }
    if (!invoiceRequest.amount && invoiceRequest.code && !invoiceRequest.fiat_amount) {
        // console.log('not amount not fiat');
        if (invoiceRequest.code.length > 1) {
            // console.log('not amount code.lenght>1');
            setCurentCurrency(true);
            setTypePage(PATH_NAME.posterminal);
        } else {
            setCurentCurrency(true);
            setTypePage(PATH_NAME.posterminal);
        }
    }
    if (invoiceRequest.amount && invoiceRequest.code && !invoiceRequest.fiat_amount) {
        const body = createUserData(invoiceRequest.id, invoiceRequest.code[0], invoiceRequest.amount, invoiceRequest.type);
        const response = await paymentLinkAPI.createInvoice(body);
        if (response) {
            localStorage.setItem(`${pathname}`, JSON.stringify(response));
            setTypePage(PATH_NAME.payment);
        }
        // console.log('all all not fiat');
    }
    if (invoiceRequest.fiat_amount && invoiceRequest.code) {
        // console.log('fiat and code');
        if (invoiceRequest.code.length > 1) {
            setCurentCurrency(true);
            setDataUser(
                createUserData(invoiceRequest.id, invoiceRequest.code, invoiceRequest.fiat_amount, invoiceRequest.type)
            );
            setTypePage(PATH_NAME.invoice);
        } else {
            const body_fiat = {
                txId: invoiceRequest.id,
                code: invoiceRequest.code[0],
                type: invoiceRequest.type,
                fiat: {
                    amount: invoiceRequest.fiat_amount,
                    currency: 'usd'
                }
            };
            const response = await paymentLinkAPI.createInvoice(body_fiat);
            if (response) {
                localStorage.setItem(`${pathname}`, JSON.stringify(response));
                setTypePage(PATH_NAME.payment);
            }
        }
    }
    if (invoiceRequest.fiat_amount && !invoiceRequest.code) {
        // console.log('fiat');
        setDataUser(
            createUserData(invoiceRequest.id, invoiceRequest.code, invoiceRequest.fiat_amount, invoiceRequest.type)
        );
        setTypePage(PATH_NAME.invoice);
    }
};

export const paymentFlow = async (
    pathname,
    decodingPath,
    setTypePage,
    setCurrency,
    setDataUser,
    setError,
    setCurentCurrency,
    setTransactionStatus
) => {
    if (checkExpTime(decodingPath, setError, setTypePage)) {
        return;
    }

    const invoiceRequest = decodingPath.invoice_request;
    const data = localStorage.getItem(`${pathname}`) ? JSON.parse(localStorage.getItem(`${pathname}`) ?? '') : {};
    const hashLocalStorage = localStorage.getItem('hash');

    if (localStorage[`${pathname}`] || hashLocalStorage) {
        const {status} = data;

        if (status === TRANSACTIONS_STATUSES.EXPIRED) {
            setTypePage(PATH_NAME.payment); //последний экран неуспех
            setTransactionStatus(TRANSACTIONS_STATUSES.EXPIRED);
            return;
        }
        if (status === TRANSACTIONS_STATUSES.CONFIRMED || status === TRANSACTIONS_STATUSES.PROCESSED || status === TRANSACTIONS_STATUSES.PROCESSING) {
            setTypePage(PATH_NAME.payment); //последний экран успех
            setTransactionStatus(TRANSACTIONS_STATUSES.CONFIRMED);
            return;
        }
        if (status === TRANSACTIONS_STATUSES.FAILED || status === TRANSACTIONS_STATUSES.DUST) {
            setTypePage(PATH_NAME.payment); //последний экран неуспех
            setTransactionStatus(TRANSACTIONS_STATUSES.FAILED);
            return;
        }
        if (status === TRANSACTIONS_STATUSES.WAITING) {
            setTypePage(PATH_NAME.payment); //последний экран процесс
            setTransactionStatus(TRANSACTIONS_STATUSES.WAITING);
            return;
        }
    }
    if (invoiceRequest.type !== 'invoice_type_user') {
        // console.log('No user!!!!!!!!!!!!!1');
        if (!invoiceRequest.id) {
            // console.log('not id');
            setTypePage(PATH_NAME.error);
            setError('Error');
            return;
        }

        const transaction = await paymentLinkAPI.getTransactionIncoming(invoiceRequest.id).catch((res) => undefined);

        if (transaction) {
            localStorage.setItem(`${pathname}`, JSON.stringify(transaction));
            const {status} = transaction;
            // console.log(status)
            if (status === TRANSACTIONS_STATUSES.EXPIRED) {
                setTypePage(PATH_NAME.payment); //последний экран неуспех
                setTransactionStatus(TRANSACTIONS_STATUSES.EXPIRED);
                return;
            }
            if (status === TRANSACTIONS_STATUSES.CONFIRMED || status === TRANSACTIONS_STATUSES.PROCESSED || status === TRANSACTIONS_STATUSES.PROCESSING) {
                setTypePage(PATH_NAME.payment); //последний экран успех
                setTransactionStatus(TRANSACTIONS_STATUSES.CONFIRMED);
                return;
            }
            if (status === TRANSACTIONS_STATUSES.FAILED || status === TRANSACTIONS_STATUSES.DUST) {
                setTypePage(PATH_NAME.payment); //последний экран неуспех
                setTransactionStatus(TRANSACTIONS_STATUSES.FAILED);
                return;
            }

            if (status === TRANSACTIONS_STATUSES.PENDING) {
                setTypePage(PATH_NAME.payment); //процесс выполнения тразакции
                setDataUser(createUserData(invoiceRequest.id, invoiceRequest.code, invoiceRequest.amount, invoiceRequest.type));
                return;
            }
            if (status === TRANSACTIONS_STATUSES.WAITING) {
                setTypePage(PATH_NAME.payment); //последний экран процесс
                setTransactionStatus(TRANSACTIONS_STATUSES.WAITING);
                return;
            }
        }

        const currency: ProcessingResponce = await paymentLinkAPI.getConfigProcessing();
        setCurrency(currency.blockchains);
        setDataUser(createUserData(invoiceRequest.id, invoiceRequest.code, invoiceRequest.amount, invoiceRequest.type));

        generateFlow(invoiceRequest, setTypePage, setCurentCurrency, pathname, setDataUser);
    }
    if (invoiceRequest.type === 'invoice_type_user') {
        // console.log('user!!!!!!!!!!!!!');
        const data = localStorage.getItem(`${pathname}`) ? JSON.parse(localStorage.getItem(`${pathname}`) ?? '') : {};
        const {status} = data;
        if (status === TRANSACTIONS_STATUSES.EXPIRED) {
            setTypePage(PATH_NAME.payment); //последний экран неуспех
            setTransactionStatus(TRANSACTIONS_STATUSES.EXPIRED);
            return;
        }
        if (status === TRANSACTIONS_STATUSES.CONFIRMED || status === TRANSACTIONS_STATUSES.PROCESSED || status === TRANSACTIONS_STATUSES.PROCESSING) {
            setTypePage(PATH_NAME.payment); //последний экран успех
            setTransactionStatus(TRANSACTIONS_STATUSES.CONFIRMED);
            return;
        }
        if (status === TRANSACTIONS_STATUSES.FAILED || status === TRANSACTIONS_STATUSES.DUST) {
            setTypePage(PATH_NAME.payment); //последний экран неуспех
            setTransactionStatus(TRANSACTIONS_STATUSES.FAILED);
            return;
        }

        if (status === TRANSACTIONS_STATUSES.PENDING) {
            const {codeRequested} = data;
            if (codeRequested || invoiceRequest.code.length > 0) {
                setTypePage(PATH_NAME.payment); //процесс выполнения тразакции
                setDataUser(createUserData(invoiceRequest.id, invoiceRequest.code, invoiceRequest.amount, invoiceRequest.type));
                return;
            }
        }
        if (status === TRANSACTIONS_STATUSES.WAITING) {
            setTypePage(PATH_NAME.payment); //последний экран процесс
            setTransactionStatus(TRANSACTIONS_STATUSES.WAITING);
            return;
        }

        const currency: ProcessingResponce = await paymentLinkAPI.getConfigProcessing();
        setCurrency(currency.blockchains);
        setDataUser(createUserData(invoiceRequest.id, invoiceRequest.code, invoiceRequest.amount, invoiceRequest.type));

        generateFlow(invoiceRequest, setTypePage, setCurentCurrency, pathname, setDataUser);
    }
};
