import {createWeb3Modal} from '@web3modal/wagmi/react'
import {defaultWagmiConfig} from '@web3modal/wagmi/react/config'

import {WagmiProvider, http} from 'wagmi'
import {mainnet, sepolia, bsc, bscTestnet} from 'wagmi/chains'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {injected, walletConnect} from 'wagmi/connectors'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'dd598c9d19e47934e07d3e506b54c1d3'

const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'http://localhost:5173/',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, sepolia, bsc, bscTestnet] as const
const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    connectors: [injected(),
        walletConnect({projectId: projectId}),],
    transports: {
        [mainnet.id]: http(),
        [sepolia.id]: http(),
        [bsc.id]: http(),
        [bscTestnet.id]: http(),
    }
})

createWeb3Modal({
    wagmiConfig: config,
    projectId,
})

export function Web3ModalProvider({children}: any) {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
    )
}
