import { memo, useEffect, useMemo, useState } from 'react';
import styles from './PaymentPage.module.scss';
import { Footer } from 'pages/InvoicePage/Footer';
import logo from 'app/assets/images/logoXl.png';
import { InvoiceResponseType } from 'shared/types/types';
import { Details } from 'pages/InvoicePage/Details';
import { Payment } from 'pages/PaymentPage/Payment';
import { Steps } from 'pages/PaymentPage/Steps';
import { Accordion } from 'pages/PaymentPage/ui/Accordion/Accordion';
import { normalizationDate } from 'shared/utils/helpers';
import { ProcessingTransaction } from 'pages/PaymentPage/ProcessingTransaction';
import { SuccessTransaction } from 'pages/PaymentPage/SuccessTransaction';
import { FailedTransaction } from 'pages/PaymentPage/FailedTransaction';
import { paymentLinkAPI } from 'shared/api/api';
import { TRANSACTIONS_STATUSES } from 'shared/utils/changePath';
import { useWindowResize } from 'shared/utils/useWindowResize';
import { ResponseVendorType } from 'shared/utils/currency';
import { getCookie } from 'shared/utils/getCookie';
import { decodingByJWT } from 'shared/utils/decompress';
import { ComingSoonToYourRegion } from 'widgets/ComingSoonToYourRegion/ComingSoonToYourRegion';

type PaymentPageType = {
  transactionStatus: string;
  dataUser: any;
  setTransactionStatus: (status: TRANSACTIONS_STATUSES) => void;
};

export const PaymentPage = memo((props: PaymentPageType) => {
  const { transactionStatus, dataUser, setTransactionStatus, currency } = props;

  const location = window.location;
  const pathname = location.pathname.slice(1);
  const dateInvoiceResponse: InvoiceResponseType = localStorage.getItem(`${pathname}`)
    ? JSON.parse(localStorage.getItem(`${pathname}`) as string)
    : {};
  const [openDetails, setOpenDetails] = useState<boolean>(true);
  // const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [hash, setHash] = useState<string>('');
  const [notification, setNotification] = useState<string>('');
  const [serverError, setServerError] = useState('');
  const [error, setError] = useState(false);
  const [scroll, setScroll] = useState<number>(0);
  const [geo, setGeo] = useState<string | undefined>();
  const [vendors, setVendors] = useState<ResponseVendorType | undefined>(undefined);
  const [isAllowPaymentLinkLoading, setIsAllowPaymentLinkLoading] = useState<boolean>(false);
  const [allowPaymentLink, setAllowPaymentLink] = useState<boolean | null>(null);

  const statuses = [
    TRANSACTIONS_STATUSES.CONFIRMED,
    TRANSACTIONS_STATUSES.FAILED,
    TRANSACTIONS_STATUSES.PROCESSED,
    TRANSACTIONS_STATUSES.DUST,
    TRANSACTIONS_STATUSES.EXPIRED,
    TRANSACTIONS_STATUSES.PROCESSING
  ];

  const handleToggleClick = () => {
    setOpenDetails(!openDetails);
  };
  const date = normalizationDate(dateInvoiceResponse.createdAt);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const ringStatus = (id) => {
      return new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          paymentLinkAPI
            .getTransactionIncoming(id)
            .then((res) => {
              setTransactionStatus(res.status);
              resolve(res);
            })
            .catch((res) => {
              if (!dataUser.type === 'invoice_type_user') {
                reject(res);
              } else {
                resolve(null);
              }
            });
        }, 1000);
      });
    };

    const fetchDataUntilSuccess = async (id: string) => {
      try {
        let result = await ringStatus(id);
        while (!statuses.includes(result?.status)) {
          result = await ringStatus(id);
        }
      } catch (error) {
        setServerError(error.message);
        setError(true);
        console.error('Error response: ', error);
      }
    };

    if (dataUser.type === 'invoice_type_user') {
      if (hash) {
        fetchDataUntilSuccess(hash);
      }
    }

    if (dataUser.type !== 'invoice_type_user') {
      if (dateInvoiceResponse.txId) {
        fetchDataUntilSuccess(dateInvoiceResponse.txId);
      }
    }
    return () => clearTimeout(timeout);
  }, [hash]);

  useEffect(() => {
    if (getCookie('geo') === 'undefined') {
      return;
    }
    if (getCookie('geo') !== 'undefined' && getCookie('geo')) {
      return setGeo(getCookie('geo'));
    }
    if (!geo && !getCookie('geo')) {
      const response = paymentLinkAPI
        .getGeolocation()
        .then((res) => {
          setGeo(res.code);
          let date = new Date(Date.now() + 86400e3);
          date = date.toUTCString();
          document.cookie = `geo=${res.code}; expires=${date}`;
        })
        .catch();
    }
  }, []);

  const decodingPath = useMemo(() => decodingByJWT(pathname), [pathname]);
  useEffect(() => {
    const dataForVendors = () => {
      if (JSON.parse(localStorage.getItem(`for_vendor${dataUser.txId}`))) {
        return JSON.parse(localStorage.getItem(`for_vendor${dataUser.txId}`));
      }
      // if(dateInvoiceResponse){
      //     console.log('1111')
      //     return {
      //         code: dateInvoiceResponse.codeRequested,
      //         amount: String(dateInvoiceResponse.amountRequired)
      //     }
      // }
      else {
        if (decodingPath.invoice_request.hasOwnProperty('fiat_amount')) {
          return {
            fiat: {
              amount: decodingPath?.invoice_request?.fiat_amount,
              currency: 'usd'
            }
          };
        } else {
          return {
            code: decodingPath?.invoice_request?.code[0],
            amount: String(decodingPath?.invoice_request?.amount)
          };
        }
      }
    };
    if (geo) {
      const response = paymentLinkAPI
        .receiveVendors({
          ...dataForVendors(),
          geo: getCookie('geo'),
          code: dateInvoiceResponse.codeRequested //? dateInvoiceResponse.codeRequested
        })
        .then((res) => setVendors(res));

      setIsAllowPaymentLinkLoading(true);
      paymentLinkAPI
        .getRestrictions(geo)
        .then((res) => {
          setIsAllowPaymentLinkLoading(true);
          if (res) {
            setAllowPaymentLink(res.allowPaymentLink);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsAllowPaymentLinkLoading(false);
        });
    }
  }, [geo]);

  useEffect(() => {
    let timeout = setTimeout(() => setNotification(''), 1000);
    return () => clearTimeout(timeout);
  }, [notification]);

  useEffect(() => {
    let timeout = setTimeout(() => setError(false), 2500);
    return () => clearTimeout(timeout);
  }, [error]);

  useEffect(() => {
    let timeout = setTimeout(() => setServerError(''), 5000);
    return () => clearTimeout(timeout);
  }, [serverError]);

  useEffect(() => {
    const title = document.querySelector('title');
    title.innerHTML = `P-link ${dateInvoiceResponse?.amountRequiredUnit}`;
  }, []);

  const scrollHandler = (e) => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return function () {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const size = useWindowResize();
  const styleForScroll = size.width < 440 ? { top: `${35 + scroll}px` } : { top: `${88 + scroll}px` };

  return (
    !isAllowPaymentLinkLoading && (
      <div className={styles.wrapper}>
        <img src={logo} className={styles.logo} alt="" />
        {serverError && (
          <div className={styles.error} style={styleForScroll}>
            {serverError}
          </div>
        )}
        {notification && (
          <div className={styles.notifications} style={styleForScroll}>
            {notification}
          </div>
        )}
        {allowPaymentLink !== null && !allowPaymentLink ? (
          <ComingSoonToYourRegion />
        ) : (
          <div className={`${styles.content} ${error ? styles.content__error : ''}`}>
            <Payment
              openDetails={openDetails}
              handleToggleClick={handleToggleClick}
              dateInvoiceResponse={dateInvoiceResponse}
              exchangeRate={dateInvoiceResponse.exchangeRate.exchangeRate}
              code={dateInvoiceResponse.exchangeRate.code}
              currency={dateInvoiceResponse.exchangeRate.currency}
            />
            <Details
              openDetails={openDetails}
              order={dateInvoiceResponse.txId}
              date={date}
              amountCurrency={dateInvoiceResponse.exchangeRate.currencyAmount}
              amount={dateInvoiceResponse.amountRequiredUnit}
              code={dateInvoiceResponse.codeRequested}
            />
            <Steps transactionStatus={transactionStatus} />
            {transactionStatus === TRANSACTIONS_STATUSES.PENDING && (
              <Accordion
                dateInvoiceResponse={dateInvoiceResponse}
                setHash={setHash}
                hash={hash}
                vendors={vendors}
                setNotification={setNotification}
                dataUser={dataUser}
              />
            )}
            {transactionStatus === TRANSACTIONS_STATUSES.WAITING && (
              <ProcessingTransaction dateInvoiceResponse={dateInvoiceResponse} hash={hash} />
            )}
            {(transactionStatus === TRANSACTIONS_STATUSES.CONFIRMED ||
              transactionStatus === TRANSACTIONS_STATUSES.PROCESSED ||
              transactionStatus === TRANSACTIONS_STATUSES.PROCESSING) && (
              <SuccessTransaction dateInvoiceResponse={dateInvoiceResponse} hash={hash} />
            )}
            {(transactionStatus === TRANSACTIONS_STATUSES.FAILED ||
              transactionStatus === TRANSACTIONS_STATUSES.EXPIRED) && (
              <FailedTransaction dateInvoiceResponse={dateInvoiceResponse} transactionStatus={transactionStatus} />
            )}
          </div>
        )}
        <Footer />
      </div>
    )
  );
});
