import React, { useEffect, useLayoutEffect, useState } from 'react';

type OnRampComponentProps = {
  openedAccordions: string[];
  moonPayLink: string;
};

export const MoonPayComponent = (props: OnRampComponentProps) => {
  const [moonPayData, setMoonPayData] = useState();
  const { openedAccordions, moonPayLink } = props;
  // console.log("moonpay:1", moonPayLink)
  useEffect(() => {
    if (moonPayLink) {
      fetch(moonPayLink)
        .then((res) => res.json())
        .then(setMoonPayData)
        .catch((error) => {
          console.error('Error', error);
        });
    }
  }, [moonPayLink]);
  // console.log("moonpay:2", moonPayData)
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.moonpay.com/web-sdk/v1/moonpay-web-sdk.min.js';
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (!window.MoonPayWebSdk) {
      return;
    }

    if (window.MoonPayWebSdk && moonPayData) {
      const widget = window.MoonPayWebSdk?.init(moonPayData);
      // const widget = window.MoonPayWebSdk?.init({
      //     params: {
      //         "apiKey": "pk_test_iz1NQHO4zIFPWp1yBcnPJFPa28DWO",
      //         "currencyCode": "trx",
      //         "paymentMethod": "credit_debit_card",
      //         "quoteCurrencyAmount": 923.001,
      //         "redirectURL": "https://onramp.preprod.xamax.gpd.onl/onramp/success",
      //         "walletAddress": "TQ3pP2YedvD8RvGxk8VF24AdyzbyLETLPw",
      //         "baseCurrencyCode": "usd"
      //     },
      //     variant: "overlay",
      //     flow: "buy",
      //     environment: "sandbox",
      //     "debug": true,
      //     "containerNodeSelector": "#widget",
      //     // handlers: {
      //     //     async onTransactionCompleted(props) {
      //     //         console.log("onTransactionCompleted", props);
      //     //     },
      //     // },
      // });
      widget?.show();
      // const body = document.querySelector('body')
      const bodyDiv = document.querySelector('.moonpay-widget__overlay-container');
      const buttonMoonPay = document.querySelector('.moonpay-widget__close-overlay-button');
      const sandboxBanner = document.querySelector('.moonpay-widget__sandbox-banner');
      const iframeWidget = document.querySelector('.moonpay-widget__iframe-container');
      console.log(bodyDiv);
      console.log(buttonMoonPay);
      console.log(sandboxBanner);

      if (bodyDiv) {
        bodyDiv.style.height = '500px';
        bodyDiv.style.width = '400px';
        bodyDiv.style.position = 'static';
      }
      if (buttonMoonPay) {
        buttonMoonPay.style.display = 'none';
      }
      if (sandboxBanner) {
        sandboxBanner.style.display = 'none';
      }
      if (iframeWidget) {
        iframeWidget.style.padding = '0';
      }
      const rootMoonPay = document.querySelector('#moonpay');
      rootMoonPay.appendChild(bodyDiv);
    }
  }, [moonPayData, openedAccordions]);

  return (
    <div>
      <div
        style={{
          visibility: openedAccordions.includes('5') ? 'visible' : 'hidden',
          width: '100%',
          overflow: 'scroll',
          margin: '0 auto'
        }}
        id="moonpay"
      ></div>
    </div>
  );
};
