import {FC, memo, useEffect, useMemo, useState} from "react";
import styles from "pages/InvoicePage/Conversion.module.scss";
import {CRYPTO_SHORT_NAME, CRYPTO_VALUES_TO_DIVIDE, filterSelectedArrayOnVendors} from "shared/utils/currency";
import {paymentLinkAPI} from "shared/api/api";
import {ExchangeResponseType} from "pages/PosTerminalPage/PosTerminalPage";

type ConversionType = {
    createInvoiceHandler: () => void
    selectedCrypto: string
    dataUser: any
}


export const Conversion: FC = memo(({
                                        createInvoiceHandler,
                                        selectedCrypto,
                                        dataUser
                                    }: ConversionType) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [conversion, setConversion] = useState<ExchangeResponseType>()
    const [vendorFilter, setVendorFilter] = useState<boolean>(false)

    useEffect(() => {

        (async () => {
            setLoading(true)
            const exhData = {
                currency: "usd",
                amount: String(dataUser.amount),
                code: selectedCrypto,
                direction: "REVERSE"
            }

            const res = await paymentLinkAPI.exchangeRate(exhData)
            setConversion(res)
            setLoading(false)
        })()
    }, [selectedCrypto])


    const amount = useMemo(() => {
        if (!conversion) {
            return
        }
        const newAmount = Number(conversion?.amount) / CRYPTO_VALUES_TO_DIVIDE[selectedCrypto.toUpperCase()]
        return newAmount
    }, [conversion])

    const exchangeRate = `Exchange rate: ${conversion?.exchangeRate} USD = 1 ${CRYPTO_SHORT_NAME[conversion?.code]} `

    useEffect(() => {
        const vendorsFilter = filterSelectedArrayOnVendors(selectedCrypto)
        setVendorFilter(vendorsFilter)
    }, [selectedCrypto])


    return (
        <div className={styles.convert}>
            {selectedCrypto && !vendorFilter && <>
                <div className={styles.convert__content}>
                    <div className={styles.convert__info}>
                        <span
                            className={styles.convert__amount}>{`Amount in ${CRYPTO_SHORT_NAME[selectedCrypto]}`}</span>
                    </div>
                    <div className={styles.convert__info}>
                        <span
                            className={styles.convert__value}>{amount}</span>
                        <span className={styles.convert__currency}>{CRYPTO_SHORT_NAME[conversion?.code]}</span>
                    </div>
                </div>
                <p className={styles.convert__rate}>{!loading && exchangeRate}</p>
                <div className={styles.convert__pay}>
                    <button className={styles.convert__button} onClick={createInvoiceHandler}>PAY</button>
                </div>
            </>}
            {vendorFilter && <div className={styles.convert__pay}>
                <button className={styles.convert__button} onClick={createInvoiceHandler}>PAY</button>
            </div>}
        </div>
    )
})
