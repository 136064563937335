import { useState, type FC } from 'react';
import styles from './CopyButton.module.scss';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import icon from 'app/assets/icons/copy-btn.svg';

interface CopyButtonProps {
  className?: string;
  text?: string;
  disabled?: boolean;
}

const CopyButton: FC<CopyButtonProps> = (props) => {
  const { text, className, disabled = false } = props;

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text!);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className={classNames(styles.copy, {}, [className])}>
      <Tooltip id={text} className={styles.tooltip}>
        <p className={styles.tooltipText}>{isCopied ? 'Copied!' : 'Copy'}</p>
      </Tooltip>
      <a data-tooltip-id={text}>
        <button onClick={handleCopyClick} className={styles.button} type="button" disabled={disabled}>
          <img src={icon} alt="Copy" />
        </button>
      </a>
    </div>
  );
};

export { CopyButton };
