import styles from 'pages/PaymentPage/ui/Accordion/Accordion.module.scss';
import qr from 'app/assets/icons/qr.svg';
import copy from 'app/assets/icons/copy.svg';
import { SELECT_CRYPTO_CODES } from 'shared/utils/currency';
import QRCode from 'react-qr-code';
import { generateQrCode } from 'shared/utils/qrCode';
import { type InvoiceResponseType } from 'shared/types/types';
import walletStyles from './WalletWrapper.module.scss';
import { useCallback, useState } from 'react';
import { Button } from 'shared/ui/Button';
import classNames from 'classnames';

interface WalletWrapperType {
  openedAccordions: string[];
  handleSetOpenedAccordions: (accordion: string) => void;
  dateInvoiceResponse: InvoiceResponseType;
  copyBuffer: (text: string) => void;
  hashInput: string;
  dataUser: any;
  handlerChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSubmitHash: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const WalletWrapper = (props: WalletWrapperType) => {
  const {
    openedAccordions,
    handleSetOpenedAccordions,
    dateInvoiceResponse,
    copyBuffer,
    hashInput,
    dataUser,
    handlerChange,
    onSubmitHash
  } = props;

  const [qrStr, setQrStr] = useState<string | null>(null);

  const handleGenerateQrCode = useCallback(() => {
    const qrString = generateQrCode(dateInvoiceResponse.codeRequested, dateInvoiceResponse.walletAddress);

    setQrStr(qrString);
  }, [dateInvoiceResponse]);

  return (
    <li className={styles.accordion__item}>
      <h2
        className={styles.accordion__subtitle}
        onClick={() => {
          handleSetOpenedAccordions('1');
        }}
      >
        <img className={styles.accordion__img} src={qr} alt="" />
        <p className={styles.accordion__text}>Payment details</p>
        <button className={styles.accordion__button}>Click</button>
      </h2>
      <div className={`${styles.accordion__collapsed} ${openedAccordions.includes('1') ? styles.accordion__open : ''}`}>
        <div className={styles.accordion__qr}>
          <div className={walletStyles.paymentDetails}>
            <div className={classNames(walletStyles.paymentDetailsBlock, walletStyles.currencyDetailsBlock)}>
              <div className={walletStyles.paymentDetailsBox}>
                <p className={walletStyles.paymentDetailsBoxHeader}>Currency and network</p>
                <p className={classNames(walletStyles.paymentDetailsBlockText, walletStyles.paymentDetailsCurrency)}>
                  {SELECT_CRYPTO_CODES[dateInvoiceResponse.codeRequested]}
                </p>
              </div>
              <div className={walletStyles.paymentDetailsBox}>
                <p className={walletStyles.paymentDetailsBoxHeader}>Amount</p>
                <p className={classNames(walletStyles.paymentDetailsBlockText, walletStyles.paymentDetailsAmount)}>
                  {dateInvoiceResponse.amountRequiredUnit}
                </p>
              </div>
              <button
                className={styles.accordion__buffer}
                onClick={() => {
                  copyBuffer(dateInvoiceResponse.amountRequiredUnit);
                }}
              >
                <img className={styles.accordion__copy} src={copy} alt="" />
              </button>
            </div>
            <div className={classNames(walletStyles.paymentDetailsBlock, walletStyles.walletDetailsBlock)}>
              <div className={walletStyles.paymentDetailsBox}>
                <p className={walletStyles.paymentDetailsBoxHeader}>Wallet address</p>
                <p className={classNames(walletStyles.paymentDetailsBlockText, walletStyles.paymentDetailsWallet)}>
                  {dateInvoiceResponse.walletAddress}
                </p>
              </div>
              <button
                className={styles.accordion__buffer}
                onClick={() => {
                  copyBuffer(dateInvoiceResponse.walletAddress);
                }}
              >
                <img className={styles.accordion__copy} src={copy} alt="Copy" />
              </button>
            </div>
          </div>
          <div className={styles.accordion__code}>
            {qrStr ? (
              <QRCode className={styles.accordion__qrcode} value={qrStr} />
            ) : (
              <Button
                type="button"
                appearance="secondary"
                onClick={handleGenerateQrCode}
                className={styles.generateQrCodeBtn}
              >
                QR Code
              </Button>
            )}
          </div>
          <p className={styles.accordion__warning}>
            Sending funds to the wrong network or token can result in <br></br>the <span>loss of funds</span>.
          </p>
        </div>
        {dataUser.type === 'invoice_type_user' && (
          <form onSubmit={onSubmitHash}>
            <div className={walletStyles.accordion__user}>
              <div className={walletStyles.textBox}>
                <h3 className={walletStyles.hashTitle}>Confirm payment</h3>
                <p className={walletStyles.subTitle}>
                  Please enter the Hash ID number to confirm your crypto transaction.
                </p>
              </div>
              <input
                className={walletStyles.hashInput}
                placeholder="Enter Hash ID/TXN Hash"
                type="text"
                value={hashInput}
                onChange={handlerChange}
              />
              <button className={walletStyles.hashSubmitBtn} type="submit">
                Confirm
              </button>
            </div>
          </form>
        )}
      </div>
    </li>
  );
};
