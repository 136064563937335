import {useEffect, useState} from "react";
import {BaseError, useAccount, useDisconnect, useSendTransaction, useWaitForTransactionReceipt} from "wagmi";
import {paymentLinkAPI} from "shared/api/api";
import {CRYPTO_VALUES_TO_DIVIDE, findDataBlockChain} from "shared/utils/currency";
import {parseEther} from "viem";
import styles from "./ButtonConnectToWalletFromBitcoinFork.module.scss";
import copy from "app/assets/icons/copy.svg";
import {exceptionForWindows, generateLinksForBitcoinFork} from "shared/utils/qrCode";
import {useWeb3Modal} from '@web3modal/wagmi/react';
import BigNumber from "bignumber.js";

type ButtonConnectToWalletProps = {
    code: string;
    amount: string;
    wallet: string;
    copyBuffer: (data: string) => void
}

export const ButtonConnectToWalletFromBitcoinFork = ({
                                                         currency,
                                                         code,
                                                         amount,
                                                         wallet,
                                                         copyBuffer
                                                     }: ButtonConnectToWalletProps) => {
    const [cryptoCurrency, setCryptoCurrency] = useState()

    useEffect(() => {
        paymentLinkAPI.getConfigProcessing().then(res => setCryptoCurrency(findDataBlockChain(res.blockchains, code)))
    }, [])

    console.log(window.navigator.platform.includes('Win'))
    // console.log(exceptionForWindows(code, wallet, amount))
    const dev1 = new BigNumber(amount)
    const dev2 = new BigNumber(CRYPTO_VALUES_TO_DIVIDE[code.toUpperCase()])
    const newAmount = dev1.dividedBy(dev2)
    return (
        <div className="container">
            <div className="stack">
                <div className={styles.connect}>
                    {/*{window.navigator.platform.includes('Win') ?*/}
                    {/*    <p>this type of connection is not suitable for this operating system</p> :*/}
                    <a href={generateLinksForBitcoinFork(code, wallet, newAmount)}>
                        <button className={styles.connect__button}>Wallet Connect</button>
                    </a>
                    {/*}*/}
                </div>
                {/*<div className={styles.about}>*/}
                {/*    {hash && <div className={styles.hash}>*/}
                {/*        <p className={styles.hash__text}>{`Transaction Hash: ${hash.slice(0, 5)}...${hash.slice(50)}`}</p>*/}
                {/*        <button*/}
                {/*            className={styles.hash__buffer}*/}
                {/*            onClick={() => copyBuffer(hash)}*/}
                {/*        >*/}
                {/*            <img className={styles.hash__copy} src={copy} alt=""/>*/}
                {/*        </button>*/}
                {/*    </div>}*/}
                {/*    {address && <div className={styles.about__text}>Wallet : {address}</div>}*/}
                {/*    {isConnected && !hash &&*/}
                {/*    <button className={styles.about__button} onClick={() => disconnect()}>Disconnected</button>}*/}
                {/*    {isConfirming && <div className={styles.about__text}>Waiting for confirmation...</div>}*/}
                {/*    {isConfirmed && <div className={styles.about__text}>Transaction confirmed.</div>}*/}
                {/*    {error && (*/}
                {/*        <div className={styles.error}>Error: {(error as BaseError).shortMessage || error.message}</div>*/}
                {/*    )}</div>*/}
            </div>
        </div>
    )
}



