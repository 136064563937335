import {memo, useMemo} from "react";
import styles from "./FailedTransaction.module.scss"
import unsuccess from "app/assets/icons/uncheck.svg";
import {InvoiceResponseType} from "shared/types/types";
import {TRANSACTIONS_STATUSES} from "shared/utils/changePath";
import {SELECT_CRYPTO_CODES} from "shared/utils/currency";
import {normalizationDate} from "shared/utils/helpers";
import {decodingByJWT} from "shared/utils/decompress";

type FailedTransactionType = {
    transactionStatus: TRANSACTIONS_STATUSES
    dateInvoiceResponse: InvoiceResponseType;
}

export const FailedTransaction = memo(({transactionStatus, dateInvoiceResponse}: FailedTransactionType) => {

    const wallet = `${dateInvoiceResponse?.walletAddress?.slice(0, 5)}...${dateInvoiceResponse?.walletAddress?.slice(-5)}`

    const date = normalizationDate(dateInvoiceResponse.createdAt)
    const location = window.location;
    const pathname = location.pathname.slice(1);
    const decodingPath = useMemo(() => decodingByJWT(pathname), [pathname]);
    console.log(decodingPath)
    const redirectOnXamax = () => {
        if (decodingPath.payment_link_metadata?.failRedirect) {
            window.location.href = decodingPath.payment_link_metadata.failRedirect
        } else {
            window.location.href = "https://xamax.io/"
        }
    }

    return (
        <div className={styles.transaction}>
            <h2 className={styles.transaction__title}>{`Payment ${transactionStatus.slice(19)}`}
                <img className={styles.transaction__img} src={unsuccess} alt=""/>
            </h2>
            <h4 className={styles.transaction__subtitle}>
                <span>Payment has not been received.</span>
                <span>We cannot found transaction at blockchain and failed it</span></h4>
            <ul className={styles.transaction__info}>
                <li className={styles.transaction__item}>
                    <span className={styles.transaction__row}>Network</span>
                    <span
                        className={styles.transaction__row}>{SELECT_CRYPTO_CODES[dateInvoiceResponse.codeRequested]}</span>
                </li>
                <li className={styles.transaction__item}>
                    <span className={styles.transaction__row}>To</span>
                    <span className={styles.transaction__row}>{wallet}</span>
                </li>
                <li className={styles.transaction__item}>
                    <span className={styles.transaction__row}>Transaction time</span>
                    <span className={styles.transaction__row}>{date}</span>
                </li>
            </ul>
            {/*<div className={styles.transaction__send}>*/}
            {/*    <h4 className={styles.transaction__subheading}>Send info to e-mail</h4>*/}
            {/*    <input className={styles.transaction__email} type="email"/>*/}
            {/*</div>*/}
            <div className={styles.transaction__submit}>
                <button className={styles.transaction__button} onClick={redirectOnXamax}>
                    <p className={styles.transaction__text}>Completed</p>
                </button>
            </div>
            <div className={styles.transaction__blur}></div>
        </div>
    )
})
