import {memo, useEffect} from "react";
import styles from "pages/PosTerminalPage/Dial.module.scss";
import {useKeyboardBacklight} from "shared/utils/useKeyboardBacklight";


type DialType = {
    value: string;
    selected: any
    handleClickArtificialButton: (value: string, button: string) => void
    activeKey: any
}

export const Dial = memo(({value, selected, handleClickArtificialButton, activeKey}: DialType) => {

    const dialData = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "00", "0", "."]

    const key = useKeyboardBacklight()

    return (
        <div className={styles.dial}>
            <div className={styles.dial__group}>
                {dialData.map((button, index) =>
                    <button className={`${styles.dial__button} ${button === activeKey ? styles.dial__active : ""}`}
                            key={index}
                            onClick={() => handleClickArtificialButton(value, button)}
                            disabled={!selected.text}>{button}</button>)}
            </div>
        </div>
    )
})


/// большое число
