import {baseUrl} from "shared/api/url";

export const switchereHelper = (code: string, currencyAmount: number) => {

    if (currencyAmount > 13000) {
        return false
    }

    if (baseUrl === 'https://api.preprod.xamax.gpd.onl' || baseUrl === 'https://api.sandbox.xamax.io') {
        const crypto = ['btc', 'ltc', 'eth', 'usdt', 'usdt_trc20', 'trx', 'bch']
        if (crypto.includes(code)) {
            return true
        } else {
            return false
        }
    }
    if (baseUrl === 'https://api.xamax.io') {
        const crypto = ['btc', 'ltc', 'eth', 'usdt', 'usdt_trc20', 'bscusd', 'trx', 'bch']
        if (crypto.includes(code)) {
            return true
        } else {
            return false
        }
    }
}

export const getSwitchereLink = (vendors, userChange) => {
    console.log(vendors.vendors)
    const vendor = vendors.vendors.find(el => el.name === userChange.vendor)
    const methods = vendor.methods.find(el => el.type === userChange.method)
    const link = methods.link.self
    console.log(vendors)
    console.log(userChange)
    return link
}

export const switchereLinkCode = (str: any) => {
    // const str = obj.filter(el => el.name === "onramp_link")[0].href
    if (str.includes('html')) {
        return `${str.slice(0, str.length - 4)}json`
    } else {
        return `${str}`
    }

}
