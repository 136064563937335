import {useEffect, useState} from "react";
import {BaseError, useAccount, useDisconnect, useSendTransaction, useWaitForTransactionReceipt} from "wagmi";
import {paymentLinkAPI} from "shared/api/api";
import {CRYPTO_VALUES_TO_DIVIDE, findDataBlockChain} from "shared/utils/currency";
import styles from './ButtonConnectToWalletTronTether.module.scss'
import copy from "app/assets/icons/copy.svg";
import {baseUrl} from "shared/api/url";
import BigNumber from "bignumber.js";
import {tronAbi} from "shared/utils/walletConnect/abi";

type ButtonConnectToWalletProps = {
    code: string;
    amount: string;
    wallet: string;
    copyBuffer: (data: string) => void
    setHash: (hash: string) => void
}

type TronTransactionResponse = {
    result: boolean
    txid: string
    transaction: {
        visible: boolean
        txID: string
        raw_data_hex: string
        raw_data: {
            contract: Array<{
                parameter: {
                    value: {
                        to_address: string
                        owner_address: string
                        amount: number
                    }
                    type_url: string
                }
                type: string
            }>
            ref_block_bytes: string
            ref_block_hash: string
            expiration: number
            timestamp: number
        }
        signature: Array<string>
    }
}


export const ButtonConnectToWalletTronTether = ({
                                                    currency,
                                                    code,
                                                    amount,
                                                    wallet,
                                                    copyBuffer,
                                                    setHash
                                                }: ButtonConnectToWalletProps) => {
    const [cryptoCurrency, setCryptoCurrency] = useState()
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState('')
    const [initialized, setInitialized] = useState('')
    const [warning, setWarning] = useState('')
    const [hashForButton, setHashForButton] = useState('')
    // const TronAccount = 'TYNdbS2PCm415TcwVAaTLfZbzsmqkHQJvc'
    // const USDTACCOUNT = 'TEQtYgLZqx5FxuVS7ti5cJfxeBHQNZW7KN';
    // const smartContractUSDT = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'

    useEffect(() => {
        paymentLinkAPI.getConfigProcessing().then(res => setCryptoCurrency(findDataBlockChain(res.blockchains, code)))
    }, [])

    const [myMessage, setMyMessage] = useState(<h3> LOADING.. </h3>);
    const [balanceUSDT, setBalanceUSDT] = useState(0)
    const [myDetails, setMyDetails] = useState({
        name: 'none',
        address: 'none',
        balance: 0,
        frozenBalance: 0,
        network: 'none',
        link: 'false',
    });


    const getBalance = async () => {
        if (window.tronWeb && window.tronWeb.ready) {
            let walletBalances = await window.tronWeb.trx.getAccount(
                window.tronWeb.defaultAddress.base58
            );

            if (code !== 'trx') {
                let contract = await window.tronWeb.contract(tronAbi, cryptoCurrency.address);
                let Balances = await contract.balanceOf(window.tronWeb.defaultAddress.base58).call();
                setBalanceUSDT(Balances.toString(10) / CRYPTO_VALUES_TO_DIVIDE['USDT_TRC20'])
            }

            return walletBalances;
        } else {
            return 0;
        }
    };

    const checkNetwork = () => {
        if (baseUrl === 'https://api.preprod.xamax.gpd.onl' || baseUrl === 'https://api.sandbox.xamax.io') {
            console.log(myDetails.network)
            if (myDetails.network === 'https://api.trongrid.io' || myDetails.network === 'https://api.tronstack.io') {
                setWarning('The network for real payments is selected in your wallet. To perform test operations, change the network to Nile Testnet')
                setDisabled(true)
                return true
            } else {
                setWarning('')
                setDisabled(false)
            }
        }
        if (baseUrl === 'https://api.xamax.io') {

            if (myDetails.network === 'https://api.nileex.io' || myDetails.network === 'https://api.shasta.trongrid.io') {
                setWarning('A test network has been selected in your Tronlink wallet. To perform operations, change the network to the Minnet')
                setDisabled(true)
                return true
            } else {
                setWarning('')
                setDisabled(false)
            }
        }
    }

    const getWalletDetails = async () => {
        const {tronLink} = window;
        setInitialized('')
        setError('')
        if (tronLink) {
            if (window.tronWeb) {
                //checking if wallet injected
                const res: requestAccountsResponse = await tronLink.request({method: 'tron_requestAccounts'})
                if (!window.tronWeb.ready) {
                    setWarning('Check that you are logged in to your Tronlink wallet')
                }
                console.log('res:', res)
                if (window.tronWeb.ready) {
                    setWarning('')
                    let tempBalance = await getBalance();
                    let tempFrozenBalance = 0;

                    if (!tempBalance.balance) {
                        tempBalance.balance = 0;
                    }

                    //checking if any frozen balance exists
                    if (
                        !tempBalance?.frozen &&
                        !tempBalance?.account_resource?.frozen_balance_for_energy
                    ) {
                        tempFrozenBalance = 0;
                    } else {
                        if (
                            tempBalance?.frozen &&
                            tempBalance?.account_resource.frozen_balance_for_energy
                        ) {
                            tempFrozenBalance =
                                tempBalance?.frozen[0]?.frozen_balance +
                                tempBalance?.account_resource?.frozen_balance_for_energy?.frozen_balance;
                        }
                        if (
                            tempBalance?.frozen &&
                            !tempBalance?.account_resource?.frozen_balance_for_energy
                        ) {
                            tempFrozenBalance = tempBalance.frozen[0]?.frozen_balance;
                        }
                        if (
                            !tempBalance.frozen &&
                            tempBalance.account_resource?.frozen_balance_for_energy
                        ) {
                            tempFrozenBalance =
                                tempBalance.account_resource.frozen_balance_for_energy
                                    .frozen_balance;
                        }
                    }
                    console.log('address:', window.tronWeb.defaultAddress.base58)
                    //we have wallet and we are logged in
                    setMyMessage(<h3>WALLET CONNECTED</h3>);
                    setMyDetails({
                        name: window.tronWeb.defaultAddress.name,
                        address: window.tronWeb.defaultAddress.base58,
                        balance: tempBalance.balance / 1000000,
                        frozenBalance: tempFrozenBalance / 1000000,
                        network: window.tronWeb.fullNode.host,
                        link: 'true',
                    });
                } else {
                    //we have wallet but not logged in
                    setMyMessage(<h3>WALLET DETECTED PLEASE LOGIN</h3>);
                    setMyDetails({
                        name: 'none',
                        address: 'none',
                        balance: 0,
                        frozenBalance: 0,
                        network: 'none',
                        link: 'false',
                    });
                    setBalanceUSDT(0)
                }
            } else {
                //wallet is not detected at all
                setMyMessage(<h3>WALLET NOT DETECTED</h3>);
            }
        } else {
            setInitialized('Please install TronLink-Extension!')
        }

    };

    // console.log(window.tronWeb.fullNode)
    useEffect(() => {
        const onDataChange = (e) => {
            // console.log(e.data)
            // console.log(e.data.message)
            if (e.data.message && e.data.message.action == "setNode") {
                console.log("11111", e.data.message)
                getWalletDetails()
                setMyDetails({...myDetails, network: window.tronWeb.fullNode.host})
                setWarning('')
                setDisabled(false)
            }
            if (e.data.message && e.data.message.action == "disconnect") {
                setMyDetails({
                    name: 'none',
                    address: 'none',
                    balance: 0,
                    frozenBalance: 0,
                    network: 'none',
                    link: 'false',
                })
                setBalanceUSDT(0)
                console.log('got connect event', e.data)
            }
        }
        window.addEventListener('message', onDataChange)
        return () => window.removeEventListener('message', onDataChange)
    }, [myDetails, myDetails.network]);


    const calculateAmount = (amount) => {
        const dev1 = new BigNumber(amount)
        const dev2 = new BigNumber(CRYPTO_VALUES_TO_DIVIDE[code.toUpperCase()])
        const newAmount = dev1.dividedBy(dev2).toFixed()
        return Number(newAmount)
    }
    // console.log(newAmount)

    const sendUSDT = async () => {
        await getWalletDetails()
        let contract = await window.tronWeb.contract(tronAbi, cryptoCurrency.address);
        let Balances = await contract.balanceOf(window.tronWeb.defaultAddress.base58).call();
        const balance = (Balances.toString(10) / CRYPTO_VALUES_TO_DIVIDE['USDT_TRC20'])
        if (checkNetwork()) {
            return
        }
        if (myDetails.balance === 0) {
            setError('insufficient funds to pay for the transaction')
            return
        }
        console.log('balanceUSDT', balance)
        console.log('amountUSDT', calculateAmount(amount))
        if (calculateAmount(amount) > balance) {
            setError('insufficient funds')
            return
        }
        setError('')
        setDisabled(true)
        const fromAddress = myDetails.address
        const toAddress = wallet
        const contractAddress = cryptoCurrency.address // Замените на актуальный адрес контракта USDT в сети Tron
        console.log("contractAddress:", contractAddress)
        if (!tronWeb) {
            console.log('TronWeb is not initialized');
            return;
        }
        try {
            const contract = await tronWeb.contract().at(contractAddress);
            const res = await contract.transfer(toAddress, amount).send({from: fromAddress});
            if (res) {
                setHash(res)
                setHashForButton(res)
                // console.log(res)
            }
            console.log('Transfer successful');
        } catch (error) {
            setDisabled(false)
            setError(error)
            console.error('Transfer failed', error);
        }
    };

    const sendTron = async () => {
        await getWalletDetails()
        const balance = await getBalance()
        if (checkNetwork()) {
            return
        }
        // console.log(myDetails.balance)

        if (myDetails.balance === 0) {
            setError('insufficient funds to pay for the transaction')
            return
        }
        console.log('balance Tron', calculateAmount(balance?.balance))
        console.log('amount Tron', calculateAmount(amount))
        if (calculateAmount(balance?.balance) < calculateAmount(amount)) {
            setError('insufficient funds')
            return
        }
        setError('')
        setDisabled(true)
        const tronweb = tronLink.tronWeb;
        const fromAddress = tronweb.defaultAddress.base58;
        const res = await window.tronWeb.transactionBuilder.sendTrx(wallet, amount, fromAddress);

        const account = await window.tronWeb.trx.getAccount(
            window.tronWeb.defaultAddress.base58
        );
        if (window.tronLink.ready) {
            const tronweb = tronLink.tronWeb;
            const fromAddress = tronweb.defaultAddress.base58;
            const toAddress = wallet;
            const tx = await tronweb.transactionBuilder.sendTrx(toAddress, amount, fromAddress); // Step1
            try {
                const signedTx = await tronweb.trx.sign(tx); // Step2
                const hashTrx: TronTransactionResponse = await tronweb.trx.sendRawTransaction(signedTx); // Step3
                if (hashTrx.txid) {
                    setHash(hashTrx.txid)
                    setHashForButton(hashTrx.txid)
                }
                //console.log(hashTrx.txid)
            } catch (e) {
                setDisabled(false)
                setError(e)
                console.log('error', e)
            }
            console.log(account)
        }
    }
    return (
        <div className={styles.connect}>
            {myDetails.address === 'none' &&
            <button className={styles.connect__button} onClick={getWalletDetails}>Wallet Connect</button>}
            {code === 'trx' && myDetails.address !== 'none' && !hashForButton &&
            <button className={styles.connect__button} onClick={sendTron} disabled={disabled}>PAY</button>}
            {code === 'usdt_trc20' && myDetails.address !== 'none' && !hashForButton &&
            <button className={styles.connect__button} onClick={sendUSDT} disabled={disabled}>PAY</button>}
            {myDetails.address !== 'none' && <div className={styles.about}>
                {/*{myMessage}*/}
                {/*<h4>Account Name: {myDetails.name} </h4>*/}
                <h4 className={styles.about__text}>My Address: {myDetails.address}</h4>
                <h4 className={styles.about__text}>
                    Balance: {myDetails.balance} TRX (Frozen:{' '}
                    {myDetails.frozenBalance} TRX)
                </h4>
                <h4 className={styles.about__text}>
                    {code !== 'trx' && `Balance: ${balanceUSDT} USDT`}
                </h4>
                <h4 className={styles.about__text}>Network Selected: {myDetails.network}</h4>
                {error && (
                    <div className={styles.error}>
                        <span className={styles.error__symbol}>!</span>
                        <span className={styles.error__text}>Error: {error}</span>
                    </div>
                )}
                {hashForButton && <div className={styles.hash}>
                    <p className={styles.hash__text}>{`Transaction Hash: ${hashForButton.slice(0, 5)}...${hashForButton.slice(50)}`}</p>
                    <button
                        className={styles.hash__buffer}
                        onClick={() => copyBuffer(hashForButton)}
                    >
                        <img className={styles.hash__copy} src={copy} alt=""/>
                    </button>
                </div>}
            </div>}
            {initialized && <h4 className={styles.about__text}>{initialized}</h4>}
            {warning && <h4 className={styles.warning}>
                <span className={styles.warning__symbol}>!</span>
                <span className={styles.warning__text}>{warning}</span>
            </h4>}
        </div>

    );
}



