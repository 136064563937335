import {memo, useEffect, useState} from "react";
import styles from "./TestStand.module.scss";
import {baseUrl} from "shared/api/url";

export const TestStand = memo(() => {

    const [dataStand, setDataStand] = useState([
        {
            id: "1",
            isDone: false,
            text: "there is a transaction ID type not user(1 form)",
            payload: {
                "txId": String(new Date().getTime()) + "1",
            },
            response: ""
        },
        {
            id: "2",
            isDone: false,
            text: "there is a transaction ID and ONE CODE type not user(1 form)",
            payload: {
                "txId": String(new Date().getTime()) + "2",
                "code": [
                    "eth"
                ]
            },
            response: ""
        },
        {
            id: "3",
            isDone: false,
            text: "there is a transaction ID and MANY CODES type not user(1 form)",
            payload: {
                "txId": String(new Date().getTime()) + "3",
                "code": [
                    "eth",
                    "btc",
                    "usdt"
                ]
            },
            response: ""
        },
        {
            id: "4",
            isDone: false,
            text: "there is a transaction ID and ONE CODE and AMOUNT type not user(3 form)",
            payload: {
                "txId": String(new Date().getTime()) + "4",
                "code": [
                    "btc"
                ],
                "amount": "400000000"
            },
            response: ""
        },
        {
            id: "5",
            isDone: false,
            text: "there is a transaction ID and FIAT_AMOUNT type not user(2 form)",
            payload: {
                "txId": String(new Date().getTime()) + "5",
                "fiat": {
                    "amount": "40000",
                    "currency": "usd"
                }
            },
            response: ""
        },
        {
            id: "6",
            isDone: false,
            text: "there is a transaction ID and ONE CODE and type FIAT_AMOUNT not user(3 form)",
            payload: {
                "txId": String(new Date().getTime()) + "6",
                "code": [
                    "eth"
                ],
                "fiat": {
                    "amount": "40000",
                    "currency": "usd"
                }
            },
            response: ""
        },
        {
            id: "7",
            isDone: false,
            text: "there is a transaction ID and MANY CODES and FIAT_AMOUNT type not user(2 form)",
            payload: {
                "txId": String(new Date().getTime()) + "7",
                "code": [
                    "eth",
                    "btc",
                    "usdt"
                ],
                "fiat": {
                    "amount": "40000",
                    "currency": "usd"
                }
            },
            response: ""
        },
        {
            id: "8",
            isDone: false,
            text: "there is a transaction ID type USER(1 form)",
            payload: {
                "txId": String(new Date().getTime()) + "8",
                "type": "invoice_type_user"
            },
            response: ""

        },
        {
            id: "9",
            isDone: false,
            text: "there is a transaction ID and ONE CODE type USER(1 form)",
            payload: {
                "txId": String(new Date().getTime()) + "9",
                "code": [
                    "eth"
                ],
                "type": "invoice_type_user"
            },
            response: ""
        },
        {
            id: "10",
            isDone: false,
            text: "there is a transaction ID and MANY CODES type USER(1 form)",
            payload: {
                "txId": String(new Date().getTime()) + "10",
                "code": [
                    "eth",
                    "btc",
                    "usdt"
                ],
                "type": "invoice_type_user"
            },
            response: ""
        },
        {
            id: "11",
            isDone: false,
            text: "there is a transaction ID and ONE CODE and AMOUNT type USER(3 form)",
            payload: {
                "txId": String(new Date().getTime()) + "11",
                "code": [
                    "btc"
                ],
                "amount": "400000000",
                "type": "invoice_type_user"
            },
            response: ""
        },
        {
            id: "12",
            isDone: false,
            text: "there is a transaction ID and FIAT_AMOUNT type USER(2 form)",
            payload: {
                "txId": String(new Date().getTime()) + "12",
                "fiat": {
                    "amount": "40000",
                    "currency": "usd"
                },
                "type": "invoice_type_user"
            },
            response: ""
        },
        {
            id: "13",
            isDone: false,
            text: "there is a transaction ID and ONE CODE and type FIAT_AMOUNT type USER(3 form)",
            payload: {
                "txId": String(new Date().getTime()) + "13",
                "code": [
                    "eth"
                ],
                "fiat": {
                    "amount": "40000",
                    "currency": "usd"
                },
                "type": "invoice_type_user"
            },
            response: ""
        },
        {
            id: "14",
            isDone: false,
            text: "there is a transaction ID and MANY CODES and FIAT_AMOUNT type USER(2 form)",
            payload: {
                "txId": String(new Date().getTime()) + "14",
                "code": [
                    "eth",
                    "btc",
                    "usdt"
                ],
                "fiat": {
                    "amount": "40000",
                    "currency": "usd"
                },
                "type": "invoice_type_user"
            },
            response: ""
        },
    ])

    const [token, setToken] = useState("")

    const params = {
        baseURL: baseUrl,
        options: {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        }
    }

    const postPaymentLink = (data) => {

        try {
            const response = fetch(`${params.baseURL}/v1/payment-link`,
                {
                    headers: params.options.headers,
                    method: "POST",
                    body: JSON.stringify(data)
                }).then(res => res.json())
            return response
        } catch (error) {
            console.log(error)
        }
    }

    const paymentLink = async () => {
        const newDataStand = await Promise.all(dataStand.map(el => postPaymentLink(el.payload).then(res => ({
            ...el,
            response: res.href
        }))))
        setDataStand(newDataStand)
    }

    const copyBufer = (text: string) => {
        navigator.clipboard.writeText(text).then(function () {
        }, function (err) {
            console.error('Произошла ошибка при копировании текста: ', err);
        });
    }


    const complitedCase = (id: string) => {
        const newDataStand=dataStand.map((el) => el.id === id ? {...el, isDone: !el.isDone} : el)
        setDataStand(newDataStand)
    }


    return (
        <div className={styles.stand}>
            <h1 className={styles.stand__title}>Test Stand</h1>
            <div className={styles.stand__data}>
                <div className={styles.stand__form}>
                    <textarea className={styles.stand__data} placeholder={"field for save token"} id="" cols="120"
                              value={token}
                              onChange={(e) => setToken(e.currentTarget.value)}
                              rows="7"></textarea>
                    <div className={styles.stand__buttons}>
                        <button className={styles.stand__button} onClick={paymentLink}>submit</button>
                        <button className={styles.stand__button} onClick={() => setToken("")}>clear</button>
                    </div>
                </div>
            </div>
            <div className={styles.stand__container}>
                {dataStand.map(req => < div className={styles.stand__item} key={req.id}>
                    <p className={styles.stand__text}>{req.text}</p>
                    <div className={styles.stand__group}>
                        <input className={styles.stand__checkbox} type="checkbox" onChange={() => complitedCase(req.id)}
                               checked={req.isDone}/>
                        <button className={styles.stand__copy} onClick={() => copyBufer(req.response)}>copy</button>
                    </div>
                    <div className={styles.stand__token}>{req.response}</div>
                </div>)}
            </div>
        </div>
    )
})
