import { FC, memo } from 'react';
import vector from 'app/assets/icons/vector.svg';
import styles from 'pages/PaymentPage/Payment.module.scss';
import { InvoiceResponseType } from 'shared/types/types';
import { CRYPTO_ICONS, CRYPTO_SHORT_NAME, SELECT_CRYPTO_CODES } from 'shared/utils/currency';

type PaymentProps = {
  openDetails: boolean;
  handleToggleClick: () => void;
  dateInvoiceResponse: InvoiceResponseType;
  exchangeRate: number;
  code: string;
  currency: string;
};

export const Payment: FC = memo(
  ({ openDetails, handleToggleClick, dateInvoiceResponse, exchangeRate, code, currency }: PaymentProps) => {
    const imgCrypto = CRYPTO_ICONS[dateInvoiceResponse.codeRequested.toUpperCase()];
    const chain = SELECT_CRYPTO_CODES[code];

    const currencyAmountForExh = dateInvoiceResponse.exchangeRate.currencyAmount.toFixed(2);
    const exh = `Exchange rate ${dateInvoiceResponse.amountRequiredUnit} ${
      CRYPTO_SHORT_NAME[code]
    } = ${currencyAmountForExh} ${currency.toUpperCase()} `;

    return (
      <div className={styles.payment}>
        <p className={styles.payment__comment}>Invoice amount</p>
        <div className={styles.payment__content}>
          <p className={styles.payment__amount}>
            <img className={styles.payment__img} src={imgCrypto} alt="" />
            <span className={styles.payment__value}>{dateInvoiceResponse.amountRequiredUnit}</span>
            <span className={styles.payment__currency}>{CRYPTO_SHORT_NAME[dateInvoiceResponse.codeRequested]}</span>
          </p>
          <div className={styles.payment__chain}>
            <p className={styles.payment__type}>Chain type</p>
            <p className={styles.payment__network}>{chain}</p>
          </div>
          <div className={styles.payment__exchange}>
            <p className={styles.payment__rate}>{exh}</p>
            <div className={styles.payment__details} onClick={handleToggleClick}>
              <span className={styles.payment__more}>Details</span>
              <button className={`${styles.payment__button} ${openDetails ? styles.payment__rotated : ''}`}>
                <img src={vector} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
