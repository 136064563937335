import {memo} from "react";
import styles from './Loading.module.scss'
import logo from "app/assets/images/logoXl.png";

export const LoadingPage = memo(() => {
    return (
        <div className={styles.wrapper}>
            <img src={logo} alt=""/>
            <h1 className={styles.error__text}></h1>
        </div>
    )
})
