import {FC, memo} from "react";
import styles from "pages/InvoicePage/Invoice.module.scss";
import vector from "app/assets/icons/vector.svg";

type InvoiceProps = {
    openDetails: boolean,
    handleToggleClick: () => void
    dataUser: any;
}

export const Invoice: FC = memo(({openDetails, handleToggleClick, dataUser}: InvoiceProps) => {

    return (
        <div className={styles.invoice}>
            <p className={styles.invoice__comment}>Invoice amount</p>
            <div className={styles.invoice__content}>
                <div className={styles.invoice__amount}>
                    <span className={styles.invoice__currency}>$</span>
                    <span className={styles.invoice__value}>{dataUser.amount}</span>
                </div>
                <div className={styles.invoice__details} onClick={handleToggleClick}>
                    <span className={styles.invoice__more}>Details</span>
                    <button className={`${styles.invoice__button} ${openDetails ? styles.invoice__rotated : ''}`}>
                        <img src={vector} alt=""/>
                    </button>
                </div>
            </div>
        </div>
    )
})
