import {Component, memo, useEffect, useState} from "react";
import Time from "../../app/assets/icons/steps/time.svg?react"
import Processing from "../../app/assets/icons/steps/processing.svg?react"
import Sucсess from "../../app/assets/icons/steps/sucсess.svg?react"
import Unsucсes from "../../app/assets/icons/steps/unsucсess.svg?react"
import styles from "./Steps.module.scss"
import {TRANSACTIONS_STATUSES} from "shared/utils/changePath";

type StepsType = {
    step: string,
    img: string,
    active: boolean
    transactionStatus: TRANSACTIONS_STATUSES;
}

export const Steps = memo(({transactionStatus}: StepsType) => {

    const [steps, setSteps] = useState("4")


    useEffect(() => {
        switch (transactionStatus) {
            case TRANSACTIONS_STATUSES.PENDING:
                return setSteps("1")
            case TRANSACTIONS_STATUSES.WAITING:
                return setSteps("2")
            case TRANSACTIONS_STATUSES.FAILED:
                return setSteps("4")
            case TRANSACTIONS_STATUSES.EXPIRED:
                return setSteps("4")
            case TRANSACTIONS_STATUSES.DUST:
                return setSteps("4")
            case TRANSACTIONS_STATUSES.PROCESSING:
                return setSteps("3")
            case TRANSACTIONS_STATUSES.CONFIRMED:
                return setSteps("3")
            case TRANSACTIONS_STATUSES.PROCESSED:
                return setSteps("3")
        }
    }, [transactionStatus])

    const classForLeftLine = `${styles.steps__left} ${(steps === "2" || steps === "3") ? styles.steps__success : steps === "4" ? styles.steps__unsuccess : ''}`
    const classForRightLine = `${styles.steps__right} ${steps === "3" ? styles.steps__success : steps === "4" ? styles.steps__unsuccess : ''}`

    const classForOneCircle = `${styles.steps__circle} ${(steps === "1" || steps === "2" || steps === "3") ? styles.steps__active : steps === "4" ? styles.steps__failed : ''}`
    const classForTwoCircle = `${styles.steps__circle} ${(steps === "2" || steps === "3") ? styles.steps__active : steps === "4" ? styles.steps__failed : ''}`
    const classForThreeCircle = `${styles.steps__circle} ${steps === "3" ? styles.steps__active : steps === "4" ? styles.steps__failed : ''}`

    const classForOneIcone = `${styles.steps__icon} ${(steps === "1" || steps === "2" || steps === "3") ? styles.steps__light : steps === "4" ? styles.steps__failure : ''}`
    const classForTwoIcone = `${styles.steps__icon} ${(steps === "2" || steps === "3") ? styles.steps__light : steps === "4" ? styles.steps__failure : ''}`
    const classForThreeIcone = `${styles.steps__icon} ${steps === "3" ? styles.steps__light : steps === "4" ? styles.steps__failure : ''}`

    const classForOneText = `${styles.steps__text} ${(steps === "1" || steps === "2" || steps === "3") ? styles.steps__dedicated : steps === "4" ? styles.steps__signal : ''}`
    const classForTwoText = `${styles.steps__text} ${(steps === "2" || steps === "3") ? styles.steps__dedicated : steps === "4" ? styles.steps__signal : ''}`
    const classForThreeText = `${styles.steps__text} ${steps === "3" ? styles.steps__dedicated : steps === "4" ? styles.steps__signal : ''}`


    return (
        <div className={styles.steps}>
            <ul className={styles.steps__menu}>
                <li className={styles.steps__item}>
                    <div
                        className={classForOneCircle}>
                        <Time className={classForOneIcone}/>
                    </div>
                    <span className={classForOneText}>Waiting</span>
                </li>
                <li className={classForLeftLine}></li>
                <li className={styles.steps__item}>
                    <div className={classForTwoCircle}>
                        <Processing className={classForTwoIcone}/>
                    </div>
                    <span className={classForTwoText}>Processing</span>
                </li>
                <li className={classForRightLine}></li>
                <li className={styles.steps__item}>
                    <div className={classForThreeCircle}>
                        {steps === "4" ? <Unsucсes className={classForThreeIcone}/> :
                            <Sucсess className={classForThreeIcone}/>}
                    </div>
                    <span className={classForThreeText}>{steps === "4" ? "Unsuccess" : "Success"}</span>
                </li>
            </ul>
        </div>
    )
})
