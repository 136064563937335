import { memo, useEffect, useMemo } from 'react';
import styles from './SuccessTransaction.module.scss';
import success from 'app/assets/icons/checks.svg';
import { InvoiceResponseType } from 'shared/types/types';
import { CryptoName, SELECT_CRYPTO_CODES } from 'shared/utils/currency';
import { normalizationDate } from 'shared/utils/helpers';
import { decodingByJWT } from 'shared/utils/decompress';
import { TRANSACTIONS_STATUSES } from 'shared/utils/changePath';
import { CopyButton } from 'features/CopyButton';
import { TRANSACTION_HREF } from 'shared/constants/crypto/transaction-href';
import classNames from 'classnames';

type SuccessTransactionType = {
  dateInvoiceResponse: InvoiceResponseType;
  hash: string;
};

export const SuccessTransaction = memo((props: SuccessTransactionType) => {
  const { dateInvoiceResponse, hash } = props;

  const wallet = `${dateInvoiceResponse?.walletAddress?.slice(0, 5)}...${dateInvoiceResponse?.walletAddress?.slice(
    -5
  )}`;

  const date = normalizationDate(dateInvoiceResponse.createdAt);

  const newHash = dateInvoiceResponse.txHash ? dateInvoiceResponse.txHash : hash;
  const changedHash = newHash && `${newHash?.slice(0, 5)}...${newHash?.slice(-5)}`;
  const location = window.location;
  const pathname = location.pathname.slice(1);
  const decodingPath = useMemo(() => decodingByJWT(pathname), [pathname]);
  console.log(decodingPath);

  const redirectOnXamax = () => {
    if (decodingPath.payment_link_metadata?.successRedirect) {
      window.location.href = decodingPath.payment_link_metadata.successRedirect;
    } else {
      window.location.href = 'https://xamax.io/';
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (decodingPath.payment_link_metadata?.successRedirect) {
        window.location.href = decodingPath.payment_link_metadata.successRedirect;
      } else {
        // window.location.href = 'https://xamax.io/';
      }
    }, 15000);
    return () => clearTimeout(timeout);
  }, [decodingPath]);

  const transactionHref = TRANSACTION_HREF[dateInvoiceResponse.codeRequested as CryptoName];

  console.log(transactionHref);
  return (
    <div className={styles.transaction}>
      <h2 className={styles.transaction__title}>
        Payment was successful
        <img className={styles.transaction__img} src={success} alt="" />
      </h2>
      <h4 className={styles.transaction__subtitle}>
        <span>Payment has been received successfully.</span>
        <span>Thank you for your payment</span>
      </h4>
      <ul className={styles.transaction__info}>
        <li className={styles.transaction__item}>
          <span className={styles.transaction__row}>Network</span>
          <span className={styles.transaction__row}>{SELECT_CRYPTO_CODES[dateInvoiceResponse.codeRequested]}</span>
        </li>
        <li className={styles.transaction__item}>
          <span className={styles.transaction__row}>To</span>
          <div className={styles.transactionBox}>
            <CopyButton text={dateInvoiceResponse?.walletAddress} />
            <span className={styles.transaction__row}>{wallet}</span>
          </div>
        </li>
        <li className={styles.transaction__item}>
          <span className={styles.transaction__row}>Transaction time</span>
          <span className={styles.transaction__row}>{date}</span>
        </li>
        {changedHash && (
          <li className={styles.transaction__item}>
            <span className={styles.transaction__row}>Transaction hash</span>
            <div className={styles.transactionBox}>
              <CopyButton text={newHash} />
              {transactionHref ? (
                <a
                  href={transactionHref + newHash}
                  target="_blank"
                  className={classNames(styles.transaction__row, styles.transaction__hashLink)}
                >
                  {changedHash}
                </a>
              ) : (
                <span className={styles.transaction__row}>{changedHash}</span>
              )}
            </div>
          </li>
        )}
      </ul>
      <div className={styles.transaction__submit}>
        <button className={styles.transaction__button} onClick={redirectOnXamax}>
          <p className={styles.transaction__text}>Complete</p>
        </button>
      </div>
    </div>
  );
});
