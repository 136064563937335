import styles from 'app/App.module.scss';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { InvoicePage } from 'pages/InvoicePage/InvoicePage';
import { PaymentPage } from 'pages/PaymentPage/PaymentPage';
import { PosTerminalPage } from 'pages/PosTerminalPage/PosTerminalPage';
import { Blockchain } from 'shared/utils/currency';
import { LoadingPage } from 'pages/LoadingPage/Loading';
import { PATH_NAME, PathName, paymentFlow, TRANSACTIONS_STATUSES } from 'shared/utils/changePath';
import { TestStand } from 'widgets/TestStend/TestStand';
import { Signboard } from 'widgets/Signboard/Signboard';
import { decodingByJWT } from 'shared/utils/decompress';
import { baseUrl } from 'shared/api/url';

function App() {
  const [error, setError] = useState('');

  const [typePage, setTypePage] = useState<PathName>('loading');
  const [currency, setCurrency] = useState<Blockchain[]>();
  const [dataUser, setDataUser] = useState({});
  const [clientCurrency, setCurentCurrency] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState<TRANSACTIONS_STATUSES>(TRANSACTIONS_STATUSES.PENDING);

  const location = window.location;
  const pathname = location.pathname.slice(1);

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src =
      baseUrl === 'https://api.sandbox.xamax.io' || baseUrl === 'https://api.preprod.xamax.gpd.onl'
        ? 'https://sandbox.switchere.com/js/sdk-builder.js'
        : 'https://switchere.com/js/sdk-builder.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (pathname === 'debug/links') {
    return (
      <div className={styles.app}>
        <TestStand />
      </div>
    );
  } else {
    const decodingPath = useMemo(() => decodingByJWT(pathname), [pathname]);

    useEffect(() => {
      paymentFlow(
        pathname,
        decodingPath,
        setTypePage,
        setCurrency,
        setDataUser,
        setError,
        setCurentCurrency,
        setTransactionStatus
      );
    }, [decodingPath]);

    return (
      <div className={`${styles.app}${typePage === PATH_NAME.loading ? styles.loading : ''}`}>
        <Signboard />
        {typePage === PATH_NAME.loading && <LoadingPage />}
        {typePage === PATH_NAME.error && <ErrorPage error={error} />}
        {typePage === PATH_NAME.invoice && (
          <InvoicePage currency={currency} dataUser={dataUser} setTypePage={setTypePage} />
        )}
        {typePage === PATH_NAME.payment && (
          <PaymentPage
            transactionStatus={transactionStatus}
            dataUser={dataUser}
            setTransactionStatus={setTransactionStatus}
          />
        )}
        {typePage === PATH_NAME.posterminal && (
          <PosTerminalPage currency={currency} dataUser={dataUser} setTypePage={setTypePage} />
        )}
      </div>
    );
  }
}

export default App;
