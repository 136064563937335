import type { ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

export type ButtonTheme = 'primary';
export type ButtonAppearance = 'primary' | 'secondary';
export type ButtonSize = 'm';
export type ButtonWeight = '2' | '4';

const themesStyles: Record<ButtonTheme, string> = {
  primary: styles.primary
};

const appearanceStyles: Record<ButtonAppearance, string> = {
  primary: styles.appearancePrimary,
  secondary: styles.appearanceSecondary
};

const sizeStyles: Record<ButtonSize, string> = {
  m: styles.sizeM
};

const weightStyles: Record<ButtonWeight, string> = {
  2: styles.weight2,
  4: styles.weight4
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonTheme;
  appearance?: ButtonAppearance;
  size?: ButtonSize;
  weight?: ButtonWeight;
  loading?: boolean;
}

const Button: FC<Props> = ({
  theme = 'primary',
  appearance = 'primary',
  size = 'm',
  weight = '2',
  className,
  children,
  disabled,
  loading,
  ...otherProps
}) => {
  const mods = {
    [styles.disabled]: disabled || loading
  };

  const classes = [
    themesStyles[theme],
    appearanceStyles[appearance],
    sizeStyles[size],
    weightStyles[weight],
    className
  ];

  return (
    <button className={classNames(styles.button, mods, classes)} {...otherProps}>
      {children}
    </button>
  );
};

export { Button };
