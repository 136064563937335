import {CRYPTO_BUTTON_NAME} from "shared/utils/currency";

export const normalizationDate = (date) => {
    const dateObject = new Date(date);
    const day = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate()
    const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours() < 10 ? `0${dateObject.getHours()}` : dateObject.getHours();
    const minutes = dateObject.getMinutes() < 10 ? `0${dateObject.getMinutes()}` : dateObject.getMinutes();

    return (`${day}.${month}.${year} ${hours}:${minutes}`)
}



export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value.toUpperCase())
}

// export function extractAndFormatNumbers(inputString: string): string {
//     // const digitsAndDelimiters = inputString.replace(/[^0-9,.]/g, '');
//     // const stringWithDots = digitsAndDelimiters.replace(/,/g, '.');
//     // const parts = stringWithDots.split('.');
//     //
//     // return parts.slice(0, 2).join('.');
//
//
//
// }

export function extractAndFormatNumbers(
    value?: string | number,
    options?: {
        thousandSeparator?: string;
        decimalScale?: number;
        allowLeadingZeros?: boolean;
    }
) {
    if (!value) return "";
    let numberString = String(value).replace(/[^0-9.]/g, '');

    // Обработка десятичной части числа
    const parts = numberString.split('.');
    if (parts.length > 1) {
        const integerPart = parts[0];
        let decimalPart =
            options?.decimalScale !== undefined
                ? parts[1].slice(0, options.decimalScale)
                : parts[1];
        numberString = `${integerPart}.${decimalPart}`;
    }

    // Удаление ведущих нулей, если они не разрешены
    if (!options?.allowLeadingZeros) {
        if (numberString === '0' || numberString === '00') return '0';
        numberString = numberString.replace(/^0+/, '');
        // Предотвращаем удаление "0", если число действительно равно нулю
        if (numberString.startsWith('.')) {
            numberString = '0' + numberString;
        }
    }

    // Форматирование с разделителями тысяч
    if (options?.thousandSeparator) {
        const parts = numberString.split('.');
        parts[0] = parts[0].replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandSeparator
        );
        numberString = parts.join('.');
    }

    return numberString;
}

