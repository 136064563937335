import {memo} from "react";
import styles from "pages/ErrorPage/ErrorPage.module.scss";
import logo from "app/assets/images/logoXl.png";

type ErrorPageType = {
    error: string
}

export const ErrorPage = memo(({error}: ErrorPageType) => {
    return (
        <div className={styles.wrapper}>
            <img src={logo} alt=""/>
            <h1 className={styles.error__text}>{error}</h1>
        </div>
    )
})
