import styles from 'pages/PaymentPage/ui/Accordion/Accordion.module.scss';
import { VENDORS_ICONS_POPULAR } from 'shared/utils/currency';
import { createVendorData, VENDORS_SHOT_NAME } from 'shared/utils/changepayment';
import { OnRampComponent } from 'widgets/OnRampComponent/OnRampComponent';

interface OnRampMoneyWrapperType {
  onRampMoneyLink: any;
  openedAccordions: string[];
  handleSetOpenedAccordions: (accordion: string) => void;
  vendors: any;
}

export const OnRampMoneyWrapper = (props: OnRampMoneyWrapperType) => {
  const { onRampMoneyLink, openedAccordions, handleSetOpenedAccordions, vendors } = props;

  return (
    <>
      {onRampMoneyLink && (
        <li className={styles.accordion__item}>
          <h2
            className={styles.accordion__subtitle}
            onClick={() => {
              handleSetOpenedAccordions('4');
            }}
          >
            <img className={styles.accordion__img} src={VENDORS_ICONS_POPULAR[createVendorData(vendors)]} alt="" />
            <p
              className={`${styles.accordion__text} ${
                createVendorData(vendors) === 'upi' ? styles.accordion__ramp : ''
              }`}
            >
              {VENDORS_SHOT_NAME[createVendorData(vendors)]}
            </p>
            <button className={styles.accordion__button}>Click</button>
          </h2>
          <div
            className={`${styles.accordion__collapsed} ${openedAccordions.includes('4') ? styles.accordion__open : ''}`}
          >
            <div className={styles.accordion__switchere}>
              <OnRampComponent
                openedAccordions={openedAccordions}
                handleSetOpenedAccordions={handleSetOpenedAccordions}
                onRampMoneyLink={onRampMoneyLink}
              />
            </div>
          </div>
        </li>
      )}
    </>
  );
};
