import { type ChangeEvent, memo, useEffect, useState } from 'react';
import styles from 'pages/InvoicePage/InvoicePage.module.scss';
import { Invoice } from 'pages/InvoicePage/Invoice';
import { Details } from 'pages/InvoicePage/Details';
import { SelectionInvoiceCrypt } from 'pages/InvoicePage/SelectionInvoiceCrypt';
import logo from 'app/assets/images/logoXl.png';
import { Footer } from 'pages/InvoicePage/Footer';
import {
  type Blockchain,
  filterSelectedArrayOnVendors,
  filterVendorsForCode,
  type ResponseVendorType
} from 'shared/utils/currency';
import { paymentLinkAPI } from 'shared/api/api';
import { PATH_NAME } from 'shared/utils/changePath';
import { division } from 'shared/utils/bignumber';
import { getCookie } from 'shared/utils/getCookie';
import { ComingSoonToYourRegion } from 'widgets/ComingSoonToYourRegion/ComingSoonToYourRegion';

interface InvoicePageType {
  dataUser: any;
  setTypePage: (type: string) => void;
  currency: Blockchain[];
}

export const InvoicePage = memo((props: InvoicePageType) => {
  const { dataUser, setTypePage, currency } = props;

  const [error, setError] = useState(false);
  const [notification, setNotification] = useState<string>('');
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState('');
  const [serverError, setServerError] = useState('');
  const [stepNetwork, setStepNetwork] = useState('1');
  const [network, setNetwork] = useState(false);
  const [geo, setGeo] = useState<string | undefined>();
  const [vendors, setVendors] = useState<ResponseVendorType | undefined>(undefined);
  const networkArr = ['usdt', 'usdt_trc20', 'bscusd'];
  const [isAllowPaymentLinkLoading, setIsAllowPaymentLinkLoading] = useState<boolean>(false);
  const [allowPaymentLink, setAllowPaymentLink] = useState<boolean | null>(null);

  const handleToggleClick = () => {
    setOpenDetails(!openDetails);
  };

  const changeRadioButtonValue = (e: ChangeEvent<HTMLInputElement>) => {
    setServerError('');
    setSelectedCrypto(e.currentTarget.value);
    // console.log("changeRadioButtonValue", e.currentTarget.value)

    if (!networkArr.includes(e.currentTarget.value)) {
      setStepNetwork('3');
      setNetwork(false);
    }
    if (networkArr.includes(e.currentTarget.value)) {
      setNetwork(true);
      setStepNetwork('2');
    }
  };

  const changeNetworkValue = (e: ChangeEvent<HTMLInputElement>) => {
    setServerError('');
    setSelectedCrypto(e.currentTarget.value);
    // console.log("changeNetworkValue", e.currentTarget.value)

    setStepNetwork('3');
  };

  const location = window.location;
  const pathname = location.pathname.slice(1);

  const getCode = (vendors, value) => {
    const vendor = filterSelectedArrayOnVendors(value);
    if (!vendor) {
      return value;
    } else {
      const data = filterVendorsForCode(vendors, value);
      localStorage.setItem(String(dataUser.txId), JSON.stringify(data));
      return data.code;
    }
  };

  const createInvoiceHandler = async () => {
    // console.log(getCode(vendors, selectedCrypto))
    const body = {
      txId: String(dataUser.txId),
      code: getCode(vendors, selectedCrypto),
      type: dataUser.type,
      fiat: {
        amount: dataUser.amount,
        currency: 'usd'
      }
    };

    localStorage.setItem(`for_vendor${dataUser.txId}`, JSON.stringify(body));
    const response = await paymentLinkAPI.createInvoice(body).catch((res) => {
      const error = JSON.parse(res.message);
      // console.log(error)
      // console.log(error.resCode)
      if (error.resCode === 3) {
        setServerError(
          `${error.message.charAt(0).toUpperCase()}${error.message.slice(1)} ${division(
            error.value,
            error.code.toUpperCase()
          )} ${error.code.toUpperCase()}`
        );
        setError(true);
      }
      if (error.resCode === 6) {
        setServerError(`${error.message.charAt(0).toUpperCase()}${error.message.slice(1)}`);
        setError(true);
      }
    }); // local storage&&
    if (response) {
      localStorage.setItem(`${pathname}`, JSON.stringify(response));
      setTypePage(PATH_NAME.payment);
    }
  };

  useEffect(() => {
    // console.log(12, getCookie('geo'))
    if (getCookie('geo') === 'undefined') {
      return;
    }
    if (getCookie('geo') !== 'undefined' && getCookie('geo')) {
      setGeo(getCookie('geo'));
      return;
    }
    if (!geo && !getCookie('geo')) {
      const response = paymentLinkAPI
        .getGeolocation()
        .then((res) => {
          setGeo(res.code);
          let date = new Date(Date.now() + 86400e3);
          date = date.toUTCString();
          document.cookie = `geo=${res.code}; expires=${date}`;
        })
        .catch();
      // console.log(response)
    }
  }, []);

  useEffect(() => {
    if (geo) {
      const response = paymentLinkAPI
        .receiveVendors({
          geo: getCookie('geo'),
          fiat: {
            amount: dataUser.amount,
            currency: 'usd'
          }
        })
        .then((res) => {
          setVendors(res);
        });

      setIsAllowPaymentLinkLoading(true);
      paymentLinkAPI
        .getRestrictions(geo)
        .then((res) => {
          setIsAllowPaymentLinkLoading(true);
          if (res) {
            setAllowPaymentLink(res.allowPaymentLink);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsAllowPaymentLinkLoading(false);
        });
    }
  }, [geo]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNotification('');
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [notification]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError(false);
    }, 2500);
    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setServerError('');
    }, 2500);
    return () => {
      clearTimeout(timeout);
    };
  }, [serverError]);

  useEffect(() => {
    const title = document.querySelector('title');
    title.innerHTML = 'P-link';
  }, []);

  return (
    !isAllowPaymentLinkLoading && (
      <div className={styles.wrapper}>
        <img src={logo} className={styles.logo} alt="" />
        {serverError && <div className={styles.server}>{serverError}</div>}
        {allowPaymentLink !== null && !allowPaymentLink ? (
          <ComingSoonToYourRegion />
        ) : (
          <div className={`${styles.content} ${error ? styles.error : ''}`}>
            <Invoice openDetails={openDetails} handleToggleClick={handleToggleClick} dataUser={dataUser} />
            <Details
              openDetails={openDetails}
              order={dataUser.txId}
              amount={dataUser.amount}
              amountCurrency={dataUser.amount}
              code={'usd'}
            />
            <SelectionInvoiceCrypt
              currency={currency}
              changeRadioButtonValue={changeRadioButtonValue}
              changeNetworkValue={changeNetworkValue}
              selectedCrypto={selectedCrypto}
              dataUser={dataUser}
              createInvoiceHandler={createInvoiceHandler}
              stepNetwork={stepNetwork}
              networkArr={networkArr}
              network={network}
              vendors={vendors}
              setSelectedCrypto={setSelectedCrypto}
              setStepNetwork={setStepNetwork}
            />
          </div>
        )}
        <Footer />
      </div>
    )
  );
});
