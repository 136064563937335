import {ChangeEvent, FC, memo} from "react";
import styles from "shared/ui/RadioButton/RadioButton.module.scss";


type RadioButtonProps = {
    id: string,
    name: string,
    value: string,
    fullValue: string,
    img: string
    changeRadioButtonValue: (e: ChangeEvent<HTMLInputElement>) => void
    checked?: boolean
}

export const RadioButton: FC = memo((props: RadioButtonProps) => {

    const {id, name, value, fullValue, img, changeRadioButtonValue, checked} = props

    return (
        <div className={styles.button}>
            <input id={id} type="radio" name={name} onChange={(e) => changeRadioButtonValue(e)}
                   value={value} checked={checked}/>
            <label htmlFor={id}>
                <div className={styles.label}>
                    <img className={styles.img} src={img} alt=""/>
                    <span className={styles.name}>{fullValue}</span>
                </div>
            </label>
        </div>
    )

})
