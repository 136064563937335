import { memo } from 'react';
import styles from './ComingSoonToYourRegion.module.scss';
import icon from 'app/assets/icons/map-pin-plus.svg';

const ComingSoonToYourRegion = memo(() => {
  return (
    <div className={styles.comingSoonToYourRegion}>
      <img src={icon} alt="coming soon" />
      <h1 className={styles.title}>Coming Soon To Your Region</h1>
      <p className={styles.subtitle}>
        Our team is working diligently to expand our reach, with the goal of supporting your region in the near future
      </p>
    </div>
  );
});

export { ComingSoonToYourRegion };
