import { ButtonConnectToWalletUSDCUSDT } from 'pages/PaymentPage/ui/ButtonConnect/ButtonConnectToWalletUSDCUSDT';
import { ButtonConnectToWalletETH } from 'pages/PaymentPage/ui/ButtonConnect/ButtonConnectToWalletETH';
import { ButtonConnectToWalletBSCUSDBUSD } from 'pages/PaymentPage/ui/ButtonConnect/ButtonConnectToWalletBSCUSDBUSD';
import { ButtonConnectToWalletBNB } from 'pages/PaymentPage/ui/ButtonConnect/ButtonConnectToWalletBNB';
import { ButtonConnectToWalletFromBitcoinFork } from 'pages/PaymentPage/ui/ButtonConnect/ButtonConnectToWalletFromBitcoinFork';
import { ButtonConnectToWalletTronTether } from 'pages/PaymentPage/ui/ButtonConnect/ButtonConnectToWalletTronTether';

type ButtonConnectProps = {
  code: string;
  amount: string;
  amountUnit: string;
  wallet: string;
  copyBuffer: (data: string) => void;
  setHash: (hash: string) => void;
};

export const ButtonConnect = (props: ButtonConnectProps) => {
  const { code, amount, amountUnit, wallet, copyBuffer, setHash } = props;
  // console.log(code, amount, amountUnit, wallet)
  switch (code) {
    case 'usdt':
      return (
        <ButtonConnectToWalletUSDCUSDT
          code={code}
          amount={amount}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'usdc':
      return (
        <ButtonConnectToWalletUSDCUSDT
          code={code}
          amount={amount}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'eth':
      return (
        <ButtonConnectToWalletETH
          code={code}
          amount={amountUnit}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'busd':
      return (
        <ButtonConnectToWalletBSCUSDBUSD
          code={code}
          amount={amount}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'bscusd':
      return (
        <ButtonConnectToWalletBSCUSDBUSD
          code={code}
          amount={amount}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'bnb':
      return (
        <ButtonConnectToWalletBNB
          code={code}
          amount={amount}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'trx':
      return (
        <ButtonConnectToWalletTronTether
          code={code}
          amount={amount}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'usdt_trc20':
      return (
        <ButtonConnectToWalletTronTether
          code={code}
          amount={amount}
          wallet={wallet}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
      );
    case 'btc':
      return (
        <ButtonConnectToWalletFromBitcoinFork code={code} amount={amount} wallet={wallet} copyBuffer={copyBuffer} />
      );
    case 'bch':
      return (
        <ButtonConnectToWalletFromBitcoinFork code={code} amount={amount} wallet={wallet} copyBuffer={copyBuffer} />
      );
    case 'btg':
      return (
        <ButtonConnectToWalletFromBitcoinFork code={code} amount={amount} wallet={wallet} copyBuffer={copyBuffer} />
      );
    case 'ltc':
      return (
        <ButtonConnectToWalletFromBitcoinFork code={code} amount={amount} wallet={wallet} copyBuffer={copyBuffer} />
      );
    case 'zec':
      return (
        <ButtonConnectToWalletFromBitcoinFork code={code} amount={amount} wallet={wallet} copyBuffer={copyBuffer} />
      );
    case 'qtum':
      return (
        <ButtonConnectToWalletFromBitcoinFork code={code} amount={amount} wallet={wallet} copyBuffer={copyBuffer} />
      );
    case 'dash':
      return (
        <ButtonConnectToWalletFromBitcoinFork code={code} amount={amount} wallet={wallet} copyBuffer={copyBuffer} />
      );
  }
};
