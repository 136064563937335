import { FC } from 'react';
import styles from '../Signboard/Signboard.module.scss'
import {getSignboardText} from "shared/utils/signboard";


const Signboard: FC = () => {
  const textBoard = getSignboardText();
  return (
    <div>
      {textBoard && <div className={styles.signboard}>{textBoard}</div>}
    </div>
  );
};

export { Signboard };
