import * as fflate from 'fflate';

export const decodingByJWT = (str) => {
  const binString = str.replace(/-/g, '+').replace(/_/g, '/');
  const binaryString = atob(binString);

  const buf = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    buf[i] = binaryString.charCodeAt(i);
  }

  const bodyLen = buf.slice(4, 6);
  const bodyLenInt = bodyLen[0] | (bodyLen[1] << 8);
  const body = buf.slice(6, 6 + bodyLenInt);

  const dictV1 =
    `{"alg":"EdDSA","kid":"` +
    `","typ":"JWT"}` +
    `{"authorization":{"permissions":[` +
    `.com"` +
    `,{"scopes":["` +
    `"edit"` +
    `"create"` +
    `"list"` +
    `"read"` +
    `
		"rsname":"` +
    `}]}` +
    `"invoice_request":{` +
    `"id"` +
    `"amount"` +
    `"currency"` +
    `"fiat_currency"` +
    `"fiat_amount"` +
    `"sub":"` +
    `"exp"` +
    `callback_url` +
    `preference_list` +
    `manual_approve` +
    `default_invoice_type` +
    `minimal_amount` +
    `wallet_rotate_amount` +
    `withdrawal_fee` +
    `minimal_confirmation` +
    `token_transfer_main_currency_source` +
    `auto_withdrawal` +
    `auto_withdrawal_max_fee_limit` +
    `"code":` +
    `auto_withdrawal_destination` +
    `processing_fee` +
    `mass_payment_fee` +
    `discrepancy_invoice_amount` +
    `revolve_time` +
    `invoice` +
    `withdrawal` +
    `wallet_operation` +
    `wallet_system` +
    `wallet_invoice` +
    `wallet_cold` +
    `wallet_user` +
    `2fa_authentication` +
    `email":` +
    `,]` +
    `000000` +
    `usdt_trc20` +
    `"invoice_type_user"` +
    `"invoice_type_static"` +
    `"invoice_type_default"` +
    `"type"`;

  const decompressed = fflate.decompressSync(body, {
    dictionary: fflate.strToU8(dictV1)
  });
  const origText = fflate.strFromU8(decompressed);

  const boundaryPosition = origText.indexOf('}{') + 1;

  // Разделяем строку на две части
  const firstJsonString = origText.substring(0, boundaryPosition);
  const secondJsonString = origText.substring(boundaryPosition);

  // Парсим каждую часть как JSON
  const firstObject = JSON.parse(firstJsonString);
  const secondObject = JSON.parse(secondJsonString);

  return secondObject;
};
