import styles from 'pages/PaymentPage/ui/Accordion/Accordion.module.scss';
import { VENDORS_ICONS_POPULAR } from 'shared/utils/currency';
import { SwitchereComponent } from 'widgets/SwitchereComponent/SwitchereComponent';

interface SwitchereWrapperType {
  switchereLink: string;
  priority: string;
  openedAccordions: string[];
  handleSetOpenedAccordions: (accordion: string) => void;
}

export const SwitchereWrapper = (props: SwitchereWrapperType) => {
  const { switchereLink, priority, openedAccordions, handleSetOpenedAccordions } = props;

  return (
    <>
      {switchereLink && priority === 'switchere' && (
        <li className={styles.accordion__item}>
          <h2
            className={styles.accordion__subtitle}
            onClick={() => {
              handleSetOpenedAccordions('3');
            }}
          >
            <img className={styles.accordion__img} src={VENDORS_ICONS_POPULAR.card} alt="" />
            <p className={styles.accordion__text}>PAY BY CARD</p>
            <button className={styles.accordion__button}>Click</button>
          </h2>
          <div
            className={`${styles.accordion__collapsed} ${openedAccordions.includes('3') ? styles.accordion__open : ''}`}
          >
            <div className={styles.accordion__switchere}>
              <SwitchereComponent switchereLink={switchereLink} openedAccordions={openedAccordions} />
            </div>
          </div>
        </li>
      )}
    </>
  );
};
