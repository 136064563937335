import {memo, useEffect, useState} from "react";
import styles from "./ProcessingTransaction.module.scss"
import loader from "app/assets/icons/loader.svg";
import {InvoiceResponseType} from "shared/types/types";
import {SELECT_CRYPTO_CODES} from "shared/utils/currency";
import {normalizationDate} from "shared/utils/helpers";

type ProcessingTransactionType = {
    dateInvoiceResponse: InvoiceResponseType;
    hash: string
}


export const ProcessingTransaction = memo((props: ProcessingTransactionType) => {

    const {dateInvoiceResponse, hash} = props

    const wallet = `${dateInvoiceResponse?.walletAddress?.slice(0, 5)}...${dateInvoiceResponse?.walletAddress?.slice(-5)}`

    const date = normalizationDate(dateInvoiceResponse.createdAt)

    const newHash = dateInvoiceResponse.txHash ? dateInvoiceResponse.txHash : hash
    const changedHash = newHash && `${newHash?.slice(0, 5)}...${newHash?.slice(-5)}`

    const [isRotating, setIsRotating] = useState(false);

    useEffect(() => {
        setIsRotating(true)
    }, [])

    return (
        <div className={styles.transaction}>
            <h2 className={styles.transaction__title}>Processing payment...</h2>
            <div className={styles.transaction__loading}>
                <img className={`${styles.transaction__img} ${isRotating ? styles.rotateAnimation : ''}`} src={loader}
                     alt=""/>
            </div>
            <ul className={styles.transaction__info}>
                <li className={styles.transaction__item}>
                    <span className={styles.transaction__row}>Network</span>
                    <span
                        className={styles.transaction__row}>{SELECT_CRYPTO_CODES[dateInvoiceResponse.codeRequested]}</span>
                </li>
                <li className={styles.transaction__item}>
                    <span className={styles.transaction__row}>To</span>
                    <span className={styles.transaction__row}>{wallet}</span>
                </li>
                <li className={styles.transaction__item}>
                    <span className={styles.transaction__row}>Transaction time</span>
                    <span className={styles.transaction__row}>{date}</span>
                </li>
                {changedHash && <li className={styles.transaction__item}>
                    <span className={styles.transaction__row}>Transaction hash</span>
                    <span className={styles.transaction__row}>{changedHash}</span>
                </li>}
            </ul>
        </div>
    )
})
