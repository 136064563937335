export const changePayment = (vendors) => {
  const vendorsPriority = {};
  vendors.vendors.forEach((el) => {
    vendorsPriority[el.name] = {
      rate: el.methods[0].rate,
      link: el.name === 'onrampmoney' ? el.methods[0].link.self : el.methods[1].link.self
    };
  });
  return vendorsPriority;
};

export const createVendorData = (vendors) => {
  const onrampVendor = vendors.vendors.find((el) => el.name === 'onrampmoney');
  const method = onrampVendor.methods[0].type;
  return method;
};

export const VENDORS_SHOT_NAME = {
  card: 'CARD',
  upi: 'UPI',
  fast: 'FAST',
  united_arab_emirates_bank_transfer: 'BANK UAE',
  spei: 'SPEI',
  vietqr: 'VIET QR',
  nigeria_bank_transfer: 'BANK NGN',
  pix: 'PIX BR',
  khipu: 'KHIPU',
  paloma_bank_transfer: 'PALOMA',
  sepa_instant: 'SEPA',
  indonesia_bank_transfer: 'BANK IDR',
  mpesa: 'MPESA',
  instant_eft: 'EFT',
  thai_qr: 'THAI QR',
  malaysia_bank_transfer: 'BANK MYR'
};
