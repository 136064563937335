import {baseUrl} from "shared/api/url";

export function getSignboardText() {
    let textBoard: string;

    if (baseUrl === 'https://api.sandbox.xamax.io') {
        textBoard = 'SANDBOX';
    } else if (baseUrl === 'https://api.preprod.xamax.gpd.onl') {
        textBoard = 'PREPROD';
    } else return null;

    return textBoard;
}
