import {ChangeEvent, forwardRef, useMemo} from 'react';
import styles from 'pages/PosTerminalPage/PaymentCurrency.module.scss';
import buttonDelete from 'app/assets/icons/buttonDelete.svg';
import buttonDeleteActive from 'app/assets/icons/buttonDeleteActive.svg';
import {Dial} from 'pages/PosTerminalPage/Dial';
import {Tooltip} from 'pages/PosTerminalPage/Tooltip';
import usd from 'app/assets/icons/crypto/usd.svg';
import {CRYPTO_SHORT_NAME, CRYPTO_VALUES_TO_DIVIDE, MINIMUM_TRANSACTION_AMOUNT} from 'shared/utils/currency';
import {extractAndFormatNumbers} from 'shared/utils/helpers';
import BigNumber from 'bignumber.js';
import {SelectedType} from 'pages/PosTerminalPage/PosTerminalPage';
import {useWindowResize} from "shared/utils/useWindowResize";


type PaymentCurrencyType = {
    selected: SelectedType;
    value: string;
    setValue: (value: string) => void;
    conversion: any;
    checkBox: boolean;
    setCheckBoxValue: (checked: boolean) => void;
    setConversion: (amount: string) => void;
    loading: boolean;
    error: string;
    onEnterHandler: (e: KeyboardEvent) => void
    setErrorInput: (error: string) => void
    receiveCursorPosition: () => void
    handleClickArtificialButton: (value: string, button: string) => void
    activeKey: string
    handlerDeleteOneSymbol: () => void
    handlerClear: () => void
    setServerError: any
    controller: any
};

export const PaymentCurrency = forwardRef((props: PaymentCurrencyType, ref) => {

        const {onEnterHandler, setErrorInput, receiveCursorPosition, handleClickArtificialButton} = props
        const {handlerDeleteOneSymbol, handlerClear, setServerError, setConversion, controller} = props
        const {selected, value, setValue, conversion, checkBox, setCheckBoxValue, loading, error, activeKey} = props

        // const keyboardLockWidth = (e: any) => {
        //     const size = useWindowResize()
        //     if (size.width < 440) {
        //         e.target.setAttribute('inputmode', 'none')
        //     }
        // }

        // const keyboardUnLockWidth = (e: any) => {
        //     const size = useWindowResize()
        //     if (size.width < 440) {
        //         e.target.removeAttribute('inputmode') //onBlur
        //     }
        // }

        const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            setServerError("")
            setErrorInput("")
            setConversion('')
            if(controller){
                controller.abort()
            }
            if (!selected.text) {
                return;
            }
            const filterValue = extractAndFormatNumbers(e.currentTarget.value);
            setValue(filterValue);

            // if (error) {
            //     if (filterValue < MINIMUM_TRANSACTION_AMOUNT[selected.code]) {
            //         setErrorInput(`min value ${MINIMUM_TRANSACTION_AMOUNT[selected.code]} ${CRYPTO_SHORT_NAME[selected.code].toLowerCase()}`)
            //         return
            //     } else {
            //         setErrorInput("")
            //     }
            // }
        };

        const handleInputCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
            setCheckBoxValue(e.currentTarget.checked);
            setServerError("")
            setErrorInput("")
        };

        const amount = useMemo(() => {
            if (!conversion) {
                return;
            }
            const divider = CRYPTO_VALUES_TO_DIVIDE[selected.code.toUpperCase()];
            const newAmount = checkBox
                ? new BigNumber(conversion?.amount).dividedBy(divider).toNumber()
                : new BigNumber(conversion?.amount);
            const currency = conversion?.direction === 'FORWARD' ? conversion.currency : conversion.code;
            return `${new BigNumber(newAmount).toFixed()} ${checkBox ? CRYPTO_SHORT_NAME[currency] : 'USD'}`
        }, [conversion, value]);

        return (
            <div className={styles.payment}>
                <button className={`${styles.payment__clear} ${value ? styles.payment__clearActive : ""}`}
                        onClick={handlerClear}>
                    Clear
                </button>
                <input ref={ref}
                       className={`${styles.payment__input} ${error ? styles.payment__inputError : ''}`}
                       type="text"
                       placeholder={'0'}
                       value={value}
                       onKeyDown={onEnterHandler}
                       onChange={handleInputChange}
                       onClick={receiveCursorPosition}
                    // onFocus={keyboardLockWidth}
                />
                <button className={styles.payment__delete}>
                    {!!selected.text &&
                    <img className={styles.payment__coin} src={checkBox ? usd : selected.img} alt=""/>}
                    <img className={styles.payment__symbol} src={value ? buttonDeleteActive : buttonDelete} alt="clear"
                         onClick={handlerDeleteOneSymbol}/>
                </button>
                <div className={styles.payment__wrapper}>
                    {!!selected.text && value && amount && !loading && !error && (
                        <p className={styles.payment__info}>
                            <span className={styles.payment__currency}>~</span>
                            <span className={styles.payment__currency}>{amount}</span>
                        </p>
                    )}
                    {!!selected.text && value && amount && error && <p className={styles.payment__error}>{error}</p>}
                </div>
                {!!selected.text && (
                    <div className={styles.payment__checked}>
                        <div className={styles.payment__usduse}>
                            <label className={styles.payment__label}>
                                <input
                                    className={styles.payment__checkbox}
                                    type="checkbox"
                                    onChange={handleInputCheckBoxChange}
                                    value={checkBox}
                                />
                                <span className={styles.payment__box}></span>
                                USD
                            </label>
                        </div>
                        <Tooltip checkBox={checkBox}/>
                    </div>
                )}
                <Dial value={value} selected={selected} handleClickArtificialButton={handleClickArtificialButton}
                      activeKey={activeKey}/>
            </div>
        );
    }
)
