import {ChangeEvent, memo, useEffect, useMemo, useState} from "react";
import styles from "pages/InvoicePage/CryptoCurrencyGroup.module.scss";
import {RadioButton} from "shared/ui/RadioButton/RadioButton";
import {
    Blockchain,
    changeValueForButton,
    filteredOnClientsCode,
    networkFilter,
    onClientsArr,
    receiveButtonsPaymentVendors,
    receivePopularPaymentVendor,
    ResponseVendorType,
    vendors,
    VENDORS_ICONS,
    VENDORS_ICONS_POPULAR
} from "shared/utils/currency";
import {Switch} from "shared/ui/Switch/Switch";
import del from '../../app/assets/icons/delete_popular.svg'


type CryptoCurrencyType = {
    currency: Blockchain[]
    changeRadioButtonValue: (e: ChangeEvent<HTMLInputElement>) => void
    dataUser: any
    createStepNetwork: (step: string) => void
    vendors: ResponseVendorType | undefined
    selectedCrypto: any
    setSelectedCrypto: any
    setStepNetwork: any
}

export const CryptoCurrencyGroup = memo((props: CryptoCurrencyType) => {

    const {currency, changeRadioButtonValue, dataUser, createStepNetwork, vendors} = props
    const {selectedCrypto, setSelectedCrypto, setStepNetwork} = props

    const popularVendor = useMemo(() => {
        if (vendors) {
            return receivePopularPaymentVendor(vendors)
        }
    }, [vendors])

    const [coins, setCoins] = useState([])
    const [checked, setChecked] = useState(true);
    const [popular, setDeletePopular] = useState(false)

    const popularCoin = ["btc", "eth", "usdt", "usdc", "bnb", "trx"]


    useEffect(() => {
        if (dataUser.code) {
            if (dataUser.code.length === 1) {
                setCoins(filteredOnClientsCode(currency, dataUser.code))
            } else {
                setCoins(onClientsArr(currency, dataUser.code))
            }
        } else {
            if (checked) {
                setCoins(networkFilter(currency))
            } else {
                setCoins(filteredOnClientsCode(currency, popularCoin))
            }
        }
    }, [currency, checked])


    const handleClousePopular = () => {
        setDeletePopular(true)
    }

    useEffect(() => {
        if (!selectedCrypto && popularVendor) {
            setStepNetwork('3')
            setSelectedCrypto(popularVendor.type)
        }

    }, [vendors, popularVendor])

    return (
        <div className={styles.select}>
            <div className={styles.select__content}>
                <h4 className={styles.select__subtitle}>Pay by</h4>
                {!dataUser.code && <div className={styles.select__switch}>
                    <span className={styles.select__currency}>Popular</span>
                    <Switch
                        isOn={checked}
                        handleToggle={() => setChecked(!checked)}
                        colorOne="#999999"
                        colorTwo="#8CC63F"
                    />
                    <span className={styles.select__currency}>All</span>
                </div>}
            </div>
            {popularVendor &&
            <div className={`${styles.select__poular} ${popular ? styles.select__close : ''}`}>
                <p className={styles.select__foruser}>Popular in your region</p>
                <img className={styles.select__vender} src={VENDORS_ICONS_POPULAR[popularVendor?.type]} alt=""/>
                <button className={styles.select__button} onClick={handleClousePopular}>
                    <img src={del} alt="close"/>
                </button>
            </div>
            }
            <div className={styles.select__group}>
                {receiveButtonsPaymentVendors(vendors).concat(coins).map(coin => <RadioButton key={coin.value}
                                                                                              id={coin.value}
                                                                                              name={coin.name}
                                                                                              changeRadioButtonValue={changeRadioButtonValue}
                                                                                              // createStepNetwork={createStepNetwork}
                                                                                              value={coin.value}
                                                                                              checked={coin.value === selectedCrypto}
                                                                                              img={coin.img}
                                                                                              fullValue={changeValueForButton(coin)}/>)
                }
            </div>
        </div>
    )
})
