const test = import.meta.env.VITE_VERCEL_ENV === 'development' || import.meta.env.VITE_VERCEL_ENV === 'preview';

export type CryptoName =
  | 'eth'
  | 'btc'
  | 'bch'
  | 'btg'
  | 'zec'
  | 'dash'
  | 'ltc'
  | 'usdt'
  | 'usdc'
  | 'qtum'
  | 'trx'
  | 'usdt_trc20'
  | 'bscusd'
  | 'bnb'
  | 'busd';

export const TRANSACTION_HREF: Record<CryptoName, string> = {
  eth: test ? 'https://sepolia.etherscan.io/tx/' : 'https://etherscan.io/tx/',
  btc: test ? 'https://blockstream.info/testnet/tx/' : 'https://blockstream.info/tx/',
  bch: test
    ? 'https://blockexplorer.one/bitcoin-cash/testnet/tx/'
    : 'https://blockexplorer.one/bitcoin-cash/mainnet/tx/',
  btg: test ? '' : '',
  zec: test ? 'https://blockexplorer.one/zcash/testnet/tx/' : 'https://blockexplorer.one/zcash/mainnet/tx/',
  dash: test ? 'https://blockexplorer.one/dash/testnet/tx/' : 'https://blockexplorer.one/dash/mainnet/tx/',
  ltc: test ? 'https://blockexplorer.one/litecoin/testnet/tx/' : 'https://blockexplorer.one/litecoin/mainnet/tx/',
  usdt: test ? 'https://sepolia.etherscan.io/tx/' : 'https://etherscan.io/tx/',
  usdc: test ? 'https://sepolia.etherscan.io/tx/' : 'https://etherscan.io/tx/',
  qtum: test ? 'https://testnet.qtum.info/' : 'https://qtum.info/tx/',
  trx: test ? 'https://nile.tronscan.org/#/transaction/' : 'https://tronscan.org/#/transaction/',
  usdt_trc20: test ? 'https://nile.tronscan.org/#/transaction/' : 'https://tronscan.org/#/transaction/',
  bnb: test ? 'https://testnet.bscscan.com/tx/' : 'https://bscscan.com/tx/',
  bscusd: test ? 'https://testnet.bscscan.com/tx/' : 'https://bscscan.com/tx/',
  busd: test ? 'https://testnet.bscscan.com/tx/' : 'https://bscscan.com/tx/'
};
