import BigNumber from "bignumber.js";
import {CRYPTO_SHORT_NAMES, CryptoName} from "shared/utils/currency";

export const MINIMUM_TRANSACTION_AMOUNT: Record<CryptoName, number> = {
    btc: 0.001,
    eth: 0.01,
    usdt: 15,
    usdc: 15,
    ltc: 0.01,
    bch: 0.001,
    zec: 0.001,
    qtum: 0.001,
    btg: 0.001,
    dash: 0.001,
    trx: 15,
    usdt_trc20: 15,
    bnb: 0.1,
    bscusd: 15,
    busd: 15
};

export const CRYPTO_VALUES_TO_DIVIDE: Record<CRYPTO_SHORT_NAMES, number> = {
    BTC: 100000000,
    ETH: 1000000000000000000,
    USDT: 1000000,
    USDC: 1000000,
    LTC: 100000000,
    BCH: 100000000,
    ZEC: 100000000,
    QTUM: 100000000,
    BTG: 100000000,
    DASH: 100000000,
    TRX: 1000000,
    USDT_TRC20: 1000000,
    BNB: 1000000000000000000,
    BSCUSD: 1000000000000000000,
    BUSD: 1000000000000000000
};

export const multiplication = (value, code) => {
    const num1 = new BigNumber(value)
    const num2 = new BigNumber(CRYPTO_VALUES_TO_DIVIDE[code])
    const mult = num1.multipliedBy(num2).integerValue().toFixed()
    return mult
}

export const correctMaxFiatValueForExchange = (value) => {
    const num1 = new BigNumber(value).toString()
    return num1
}

export const division = (value, code) => {
    const num1 = new BigNumber(value)
    const num2 = new BigNumber(CRYPTO_VALUES_TO_DIVIDE[code])
    const div = num1.dividedBy(num2).toFixed()
    return div
}

export const correctMaxFiatValueForInvoice = (value) => {
    const num1 = new BigNumber(value).toNumber()
    return num1
}

export const correctAmountValueForExchange = (value) => {
    const num = new BigNumber(value).toFixed(2)
    return num
}


