import {ChangeEvent, memo} from "react";
import {CryptoCurrencyGroup} from "pages/InvoicePage/CryptoCurrencyGroup";
import {NetworkGroup} from "pages/InvoicePage/NetworkGroup";
import {Conversion} from "pages/InvoicePage/Conversion";
import {Time} from "pages/InvoicePage/Time";
import {receivePopularPaymentVendor, ResponseVendorType} from "shared/utils/currency";

type SelectionInvoiceType = {
    currency: any
    changeRadioButtonValue: (e: ChangeEvent<HTMLInputElement>) => void
    createInvoiceHandler: () => void
    dataUser: any;
    selectedNetwork: boolean
    selectedCrypto: string
    stepNetwork: string
    createStepNetwork: (step: string) => void
    changeNetworkValue: (value: ChangeEvent<HTMLInputElement>) => void
    networkArr: string[]
    network: boolean
    vendors: ResponseVendorType | undefined
    setSelectedCrypto: any
    setStepNetwork: any
}

export const SelectionInvoiceCrypt = memo((props: SelectionInvoiceType) => {

    const {currency, changeRadioButtonValue, createInvoiceHandler, dataUser, network, changeNetworkValue} = props

    const {selectedCrypto, stepNetwork, vendors, setSelectedCrypto, setStepNetwork} = props

    return (
        <>
            <CryptoCurrencyGroup currency={currency} changeRadioButtonValue={changeRadioButtonValue}
                                 dataUser={dataUser} vendors={vendors} selectedCrypto={selectedCrypto}
                                 setSelectedCrypto={setSelectedCrypto} setStepNetwork={setStepNetwork}/>
            {network && <NetworkGroup
                changeRadioButtonValue={changeNetworkValue}
                dataUser={dataUser}
                selectedCrypto={selectedCrypto}
                stepNetwork={stepNetwork}
            />}
            {stepNetwork === '3' && <Conversion
                createInvoiceHandler={createInvoiceHandler}
                selectedCrypto={selectedCrypto}
                dataUser={dataUser}
            />
            }
            <Time/>
        </>
    )
})
