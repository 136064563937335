import {ChangeEvent, memo, useEffect, useState} from "react";
import styles from "pages/InvoicePage/NetworkGroup.module.scss";
import {tetherNetwork} from "shared/utils/currency";
import {RadioButton} from "shared/ui/RadioButton/RadioButton";

type NetworkGroupType = {
    changeRadioButtonValue: any
    dataUser: any
    createStepNetwork: (step: string) => void
    selectedCrypto: any
    stepNetwork: any
}

export const NetworkGroup = memo(({
                                      changeRadioButtonValue,
                                      dataUser,
                                      selectedCrypto,
                                      createStepNetwork,
                                      stepNetwork
                                  }: NetworkGroupType) => {

    const [tether, setTether] = useState(tetherNetwork)


    useEffect(() => {
        if (dataUser.code) {
            if (dataUser.code.length) {
                const newTether = tetherNetwork.filter((el) => dataUser.code.includes(el.value))
                setTether(newTether)
            }
        }

    }, [tetherNetwork])

    return (
        <div className={styles.select}>
            <h4 className={styles.select__subtitle}>Select network</h4>
            <div className={styles.select__group}>
                {tether.map(coin => <RadioButton key={coin.id} id={coin.id} name={coin.name}
                                                 value={coin.value}
                                                 checked={coin.value === selectedCrypto && stepNetwork === '3'}
                                                 changeRadioButtonValue={changeRadioButtonValue}
                    // createStepNetwork={createStepNetwork}
                                                 fullValue={coin.fullValue} img={coin.img}/>)}
            </div>
        </div>
    )
})
