import styles from 'pages/PaymentPage/ui/Accordion/Accordion.module.scss';
import { VENDORS_ICONS_POPULAR } from 'shared/utils/currency';
import { MoonPayComponent } from 'widgets/MoonPayComponent/MoonPayComponent';

interface MoonPayWrapperType {
  moonPayLink: any;
  priority: string;
  openedAccordions: string[];
  handleSetOpenedAccordions: (accordion: string) => void;
}

export const MoonPayWrapper = (props: MoonPayWrapperType) => {
  const { moonPayLink, priority, openedAccordions, handleSetOpenedAccordions } = props;

  return (
    <>
      {moonPayLink && priority === 'moonpay' && (
        <li className={styles.accordion__item}>
          <h2
            className={styles.accordion__subtitle}
            onClick={() => {
              handleSetOpenedAccordions('5');
            }}
          >
            <img className={styles.accordion__img} src={VENDORS_ICONS_POPULAR.card} alt="" />
            <p className={styles.accordion__text}>PAY BY CARD</p>
            <button className={styles.accordion__button}>Click</button>
          </h2>
          <div
            className={`${styles.accordion__collapsed} ${openedAccordions.includes('5') ? styles.accordion__open : ''}`}
          >
            <div className={styles.accordion__switchere}>
              <MoonPayComponent openedAccordions={openedAccordions} moonPayLink={moonPayLink} />
            </div>
          </div>
        </li>
      )}
    </>
  );
};
