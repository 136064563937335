import {useEffect, useState} from "react";

// export const useWindowResize = () => {
//
//     const [windowSize, setWindowSize] = useState({width: undefined})
//
//     useEffect(() => {
//         const handlerResize = () => {
//             setWindowSize({width: window.innerWidth})
//         }
//
//         window.addEventListener('resize', handlerResize)
//
//         handlerResize()
//
//         return window.removeEventListener('resize', handlerResize)
//     },[])
//
//     return windowSize
// }

interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}

export const useWindowResize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};
