import {FC, memo, useEffect, useMemo, useRef, useState} from 'react';
import styles from 'pages/PosTerminalPage/PosTerminalPage.module.scss';
import {DropdownSelect} from 'pages/PosTerminalPage/DropdownSelect';
import {PaymentCurrency} from 'pages/PosTerminalPage/PaymentCurrency';
import logo from 'app/assets/images/logoXl.png';
import {Footer} from 'pages/InvoicePage/Footer';
import {
    Blockchain, CRYPTO_SHORT_NAME, CRYPTO_VALUES_TO_DIVIDE,
    filteredOnClientsCode, liftCode,
    makeSeletOptions, MINIMUM_TRANSACTION_AMOUNT
} from 'shared/utils/currency';
import {paymentLinkAPI} from 'shared/api/api';
import {PATH_NAME} from 'shared/utils/changePath';
import {extractAndFormatNumbers} from "shared/utils/helpers";
import {
    correctMaxFiatValueForExchange,
    correctMaxFiatValueForInvoice,
    division,
    multiplication
} from "shared/utils/bignumber";
import {useWindowResize} from "shared/utils/useWindowResize";
import BigNumber from "bignumber.js";


type PosTerminalPageType = {
    currency: Blockchain[];
    dataUser: any;
    setTypePage: (type: string) => void;
};

export type ExchangeResponseType = {
    currency: string;
    exchangeRate: number;
    currencyAmount: number;
    code: string;
};

export type SelectedType = {
    text: string;
    img: string;
    code: string;
};

export type InvoiceMinValueErrorType = {
    message: string
    code: string
    value: string
}

export const PosTerminalPage: FC = memo((props: PosTerminalPageType) => {

    const {currency, dataUser, setTypePage} = props

    const [serverError, setServerError] = useState("")
    const [error, setError] = useState(false)
    const [notification, setNotification] = useState<string>("")
    const [activeKey, setActiveKey] = useState<string>("")
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const [selected, setSelected] = useState<SelectedType>({text: '', img: '', code: ''});
    const [coins, setCoins] = useState([]);
    const [search, setSearch] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [conversion, setConversion] = useState<ExchangeResponseType | string>();
    const [checkBox, setCheckBoxValue] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorInput, setErrorInput] = useState<string>('');
    const [firstExh, setFirstExh] = useState<string>()
    const [scroll, setScroll] = useState<number>(0)
    const [controller, setController] = useState<AbortController>()
    const inputRef = useRef<HTMLInputElement>()
    const ButtonRef = useRef<HTMLButtonElement>()


    const minAmountForCryptoCurrency = useMemo(() => {
        if (selected.code) {
            const selectedСurrency = liftCode(currency).find(crypto => crypto.code === selected.code)
            // console.log(selectedСurrency.minAmount)
            return selectedСurrency.minAmount
        }
    }, [selected.code])

    useEffect(() => {
        // console.log(selected.code)
        if (selected.code) {
            const timeout = setTimeout(
                (async () => {
                    const exhData = {
                        currency: 'usd',
                        amount: minAmountForCryptoCurrency,
                        code: selected.code,
                        direction: 'FORWARD'
                    };
                    const res = await paymentLinkAPI.exchangeRate(exhData);
                    setFirstExh(res.amount)
                }), 0);
            return () => clearTimeout(timeout)
        }
    }, [selected.code]);

    const handleToggleClick = () => {
        setServerError("")
        if (dataUser.code && dataUser.code.length === 1) {
            return
        }
        setOpenSelect(!openSelect);
    };

    const handleSelectedClick = (text: string, img: string, code: string) => {
        setSelected({...selected, text: text, img: img, code: code});
        inputRef.current?.focus()
    };

    const onEnterHandler = (e: KeyboardEvent) => {
        setActiveKey(e.key)

        if (e.key === "Enter") {
            e.preventDefault();
            ButtonRef.current?.click()
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setActiveKey("")
        }, 500)

        return () => clearTimeout(timeout)
    }, [activeKey])


    const receiveCursorPosition = (button: string) => {
        let cursorStart = inputRef.current?.selectionStart
        if (button === "00") {
            cursorStart = cursorStart + 1
        }
        return cursorStart
    }

    const handleClickArtificialButton = (value: string, button: string) => {
        setErrorInput("")
        setServerError("")
        setConversion('')
        if (controller) {
            controller.abort()
        }
        const currentPosition = receiveCursorPosition(button)
        if (value) {
            const newValue = `${value.slice(0, currentPosition)}${button}${value.slice(currentPosition)}`
            setValue(extractAndFormatNumbers(newValue))
            // if (errorInput) {
            //     if (newValue < MINIMUM_TRANSACTION_AMOUNT[selected.code]) {
            //         setErrorInput(`min value ${MINIMUM_TRANSACTION_AMOUNT[selected.code]} ${CRYPTO_SHORT_NAME[selected.code].toLowerCase()}`)
            //         return
            //     } else {
            //         setErrorInput("")
            //     }
            // }
        } else {
            // if (errorInput) {
            //     if (value < MINIMUM_TRANSACTION_AMOUNT[selected.code]) {
            //         setErrorInput(`min value ${MINIMUM_TRANSACTION_AMOUNT[selected.code]} ${CRYPTO_SHORT_NAME[selected.code].toLowerCase()}`)
            //         return
            //     } else {
            //         setErrorInput("")
            //     }
            // }
            setValue(extractAndFormatNumbers(button))
        }
        inputRef.current?.focus()
        setTimeout(() => {
            inputRef.current?.setSelectionRange(currentPosition + 1, currentPosition + 1)
        }, 3)

    }

    const handlerDeleteOneSymbol = () => {
        setServerError("")
        setErrorInput("")
        setConversion('')
        if (controller) {
            controller.abort()
        }
        const currentPosition = receiveCursorPosition()
        if (currentPosition) {
            const newValue = `${value.slice(0, currentPosition - 1)}${value.slice(currentPosition)}`;
            setValue(newValue);
        } else {
            const newValue = value.slice(0, -1);
            setValue(newValue);
        }
        inputRef.current?.focus()
        setTimeout(() => {
            inputRef.current?.setSelectionRange(currentPosition - 1, currentPosition - 1)
        }, 3)
        // if (errorInput) {
        //     console.log(selected.code)
        //     if (value < MINIMUM_TRANSACTION_AMOUNT[selected.code]) {
        //         setErrorInput(`min value ${MINIMUM_TRANSACTION_AMOUNT[selected.code]} ${CRYPTO_SHORT_NAME[selected.code].toLowerCase()}`)
        //         return
        //     } else {
        //         setErrorInput("")
        //     }
        // }
    };

    const handlerClear = () => {
        setServerError("")
        setErrorInput("")
        setValue('');
        setConversion('')
        inputRef.current?.focus()
        // if (errorInput) {
        //     if (value < MINIMUM_TRANSACTION_AMOUNT[selected.code]) {
        //         setErrorInput(`min value ${MINIMUM_TRANSACTION_AMOUNT[selected.code]} ${CRYPTO_SHORT_NAME[selected.code].toLowerCase()}`)
        //         return
        //     } else {
        //         setErrorInput("")
        //     }
        // }
    };

    useEffect(() => {
        let newCoins;
        if (dataUser.code) {
            newCoins = filteredOnClientsCode(currency, dataUser.code);
            setCoins(newCoins);
            if (dataUser.code.length === 1) {
                setSelected({text: newCoins[0].fullValue, img: newCoins[0].img, code: newCoins[0].code});
            }
        } else {
            setCoins(makeSeletOptions(currency));
        }
    }, [currency]);


    useEffect(() => {
        const timeout = setTimeout(
            (async () => {
                if (!value) {
                    return
                }
                if (controller) {
                    controller.abort()
                }
                const newController = new AbortController()
                setController(newController)
                setLoading(true);
                const exhData = {
                    currency: 'usd',
                    amount: checkBox ? correctMaxFiatValueForExchange(value) : multiplication(value, selected.code.toUpperCase()),
                    code: selected.code,
                    direction: checkBox ? 'REVERSE' : 'FORWARD'
                };

                if (!exhData.code) {
                    return;
                }

                const res = await paymentLinkAPI.exchangeRate(exhData, newController);
                setConversion(res);
                setLoading(false);
            }), 500);
        return () => clearTimeout(timeout)
    }, [value, checkBox, selected.code]);


    const bodyFeat = {
        txId: dataUser.txId,
        code: selected.code,
        type: dataUser.type,
        fiat: {
            amount: correctMaxFiatValueForInvoice(value),
            currency: 'usd'
        }
    };


    const bodyAmount = {
        txId: dataUser.txId,
        code: selected.code,
        amount: multiplication(value, selected.code.toUpperCase()),
        type: dataUser.type
    };

    const body = checkBox ? bodyFeat : bodyAmount;

    const location = window.location;
    const pathname = location.pathname.slice(1);

    const createInvoiceHandler = async () => {

        if (body === bodyFeat && bodyFeat.fiat.amount > 1000000000000) {
            setErrorInput(`Too big fiat number accepted value up to trillion`)
            inputRef.current?.focus()
            return
        }
        localStorage.setItem(`for_vendor${dataUser.txId}`, JSON.stringify(body))
        const response = await paymentLinkAPI.createInvoice(body).catch((res) => {

            const error: InvoiceMinValueErrorType = JSON.parse(res.message)
            // console.log(error)
            // console.log(error.resCode)
            if (error.resCode === 3) {
                let newError
                if (!checkBox) {
                    newError = `${error.message.charAt(0).toUpperCase()}${error.message.slice(1)} ${division(error.value, error.code.toUpperCase())} ${CRYPTO_SHORT_NAME[error.code]}`
                } else {
                    newError = `${error.message.charAt(0).toUpperCase()}${error.message.slice(1)} ${firstExh} USD`
                }
                // setServerError(newError)
                setErrorInput(newError)
                setError(true)
            }
            if (error.resCode === 6) {
                setErrorInput(error.message)
                setError(true)
            }

        });

        if (response) {
            localStorage.setItem(`${pathname}`, JSON.stringify(response));
            setTypePage(PATH_NAME.payment);
        }
    };

    useEffect(() => {
        let timeout = setTimeout(() => setNotification(''), 2000)
        return () => clearTimeout(timeout)
    }, [notification])

    useEffect(() => {
        let timeout = setTimeout(() => setError(false), 2500)
        return () => clearTimeout(timeout)
    }, [error])

    const scrollHandler = (e) => {
        setScroll(window.scrollY)
        // const documentHeight = e.target.documentElement.scrollHeight - e.target.documentElement.clientHeight
        // const scrollPercent = (window.scrollY / documentHeight) * 100
        // console.log(scrollPercent | 0)
    }

    useEffect(() => {
        const title = document.querySelector('title')
        title.innerHTML = `P-link`
    }, [])

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return function () {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [])
    const size = useWindowResize()
    const styleForScroll = size.width < 440 ? {top: `${35 + scroll}px`} : {top: `${88 + scroll}px`}
    return (
        <div className={styles.wrapper}>
            <img src={logo} className={styles.logo} alt=""/>
            {serverError && <div className={styles.pos__server} style={styleForScroll}>{serverError}</div>}
            <div className={`${styles.pos} ${error ? styles.pos__error : ''}`}>
                <h4 className={styles.pos__title}>Request</h4>
                <DropdownSelect
                    coins={coins}
                    search={search}
                    setSearch={setSearch}
                    currency={currency}
                    dataUser={dataUser}
                    openSelect={openSelect}
                    handleToggleClick={handleToggleClick}
                    selected={selected}
                    handleSelectedClick={handleSelectedClick}
                    value={value}
                    setErrorInput={setErrorInput}
                    error={errorInput}
                    setServerError={setServerError}
                />
                <PaymentCurrency
                    loading={loading}
                    selected={selected}
                    value={value}
                    setValue={setValue}
                    conversion={conversion}
                    checkBox={checkBox}
                    setCheckBoxValue={setCheckBoxValue}
                    onEnterHandler={onEnterHandler}
                    setConversion={setConversion}
                    setErrorInput={setErrorInput}
                    error={errorInput}
                    ref={inputRef}
                    handleClickArtificialButton={handleClickArtificialButton}
                    receiveCursorPosition={receiveCursorPosition}
                    activeKey={activeKey}
                    handlerDeleteOneSymbol={handlerDeleteOneSymbol}
                    handlerClear={handlerClear}
                    setServerError={setServerError}
                    controller={controller}
                />
                <div className={styles.pos__submit}>
                    <button className={styles.pos__button} onClick={createInvoiceHandler} ref={ButtonRef}
                            disabled={!value}>
                        <p className={styles.pos__text}>PAY</p>
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    );
});
