import {FC, memo} from "react";
import styles from "pages/InvoicePage/Details.module.scss";
import {CRYPTO_SHORT_NAME} from "shared/utils/currency";

type PropsTypeDetails = {
    openDetails: boolean
    order: any,
    amount: any,
    code: any,
    date?: any
    amountCurrency?: any
}

export const Details: FC = memo(({openDetails, order, amount, code, date, amountCurrency}: PropsTypeDetails) => {

    const newDate = date?.split(" ")

    return (
        <div className={`${styles.details} ${openDetails ? styles.details__open : ''}`}>
            <h3 className={styles.details__subtitle}>Details</h3>
            <div className={styles.details__basic}>
                <div className={styles.details__discription}>
                    <span className={styles.details__name}>Invoice amount</span>
                    <span className={styles.details__name}>Order</span>
                    {newDate && <span className={styles.details__name}>Date</span>}
                </div>
                <div>
                    <p className={styles.details__currency}>
                        <span className={styles.details__value}>{amountCurrency}</span>
                        <span className={styles.details__money}>USD</span>
                    </p>
                    <p className={styles.details__currency}>{order}</p>
                    {newDate && <p className={styles.details__currency}>
                        <span className={styles.details__value}>{newDate[0]}</span>
                        <i className={styles.details__time}>{newDate[1]}</i>
                    </p>}
                </div>
            </div>
        </div>
    )

})
