import { memo, useEffect, useState } from 'react';
import { switchereLinkCode } from 'shared/utils/switchere';
import styles from './SwitchereComponent.module.scss';
import loader from 'app/assets/icons/loader.svg';

interface SwitchereType {
  switchereLink: any;
  openedAccordions: string[];
}

export const SwitchereComponent = memo(({ switchereLink, openedAccordions }: SwitchereType) => {
  const [isRotating, setIsRotating] = useState(false);

  useEffect(() => {
    setIsRotating(true);
  }, []);

  const onRampLink = switchereLinkCode(switchereLink);

  useEffect(() => {
    fetch(onRampLink)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (openedAccordions.includes('3') && data) {
          window.switchereSdk.init(data);
        }
      })
      .catch((error) => {
        console.error('Error', error);
      });
  }, [onRampLink, openedAccordions]);

  return (
    <div
      id="widget"
      style={{
        minHeight: '600px',
        maxWidth: '400px',
        width: '100%',
        overflow: 'scroll',
        margin: '0 auto'
      }}
    >
      <div className={styles.sdk}>
        <img className={`${styles.img} ${isRotating ? styles.rotateAnimation : ''}`} src={loader} alt="" />
      </div>
    </div>
  );
});
