import {decodingByJWT} from "shared/utils/decompress";
import {useEffect, useState} from "react";



export const useTimer = () => {
    const location = window.location;
    const pathname = location.pathname.slice(1);
    const decodingPath = decodingByJWT(pathname)

    const [countdownDate, setCountdownDate] = useState(new Date(decodingPath.exp * 1000))
    const [state, setState] = useState({
        hours: "00",
        minutes: "00",
        seconds: "00",
    });
    let intervalId = null

    useEffect(() => {
        intervalId = setInterval(() => setNewTime(), 1000);
        return () => clearInterval(intervalId)
    }, []);

    const setNewTime = () => {
        if (countdownDate) {
            const currentTime = new Date().getTime()

            const distanceToDate = countdownDate - currentTime;

            if (distanceToDate < 0) {
                clearInterval(intervalId)
            }
            let hours = String(Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            )).padStart(2, "0")

            let minutes = String(Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
            )).padStart(2, "0")

            let seconds = String(Math.floor((distanceToDate % (1000 * 60)) / 1000)).padStart(2, "0")


            setState({hours, minutes, seconds});
        }
    };

    return state
}


export const createDate = (data) =>{

}
