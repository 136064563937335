import BtcIcon from '../../app/assets/icons/crypto/bitcoin.svg'
import EthIcon from '../../app/assets/icons/crypto/eth.svg'
import UsdtIcon from '../../app/assets/icons/crypto/tether.svg'
import BchIcon from '../../app/assets/icons/crypto/bitcoin_cash.svg'
import DashIcon from '../../app/assets/icons/crypto/dash.svg'
import ZecIcon from '../../app/assets/icons/crypto/zec.svg'
import QtumIcon from '../../app/assets/icons/crypto/qtum.svg'
import LtsIcon from '../../app/assets/icons/crypto/ltc.svg'
import BusdIcon from '../../app/assets/icons/crypto/binance_usd.svg'
import BtgIcon from '../../app/assets/icons/crypto/bitcoin_gold.svg'
import TronIcon from '../../app/assets/icons/crypto/tron.svg'
import UsdcIcon from '../../app/assets/icons/crypto/usd_coin.svg'
import BnbIcon from '../../app/assets/icons/crypto/binance_coin.svg'
import TronUsdtIcon from '../../app/assets/icons/crypto/tron-usdt.svg'
import BscUsdIcon from '../../app/assets/icons/crypto/bscusd.svg'
import {FunctionComponent} from "react";
import card from '../../app/assets/icons/vendors/card.png'
import bankUAE from '../../app/assets/icons/vendors/bankUAE.png'
import bankNGN from '../../app/assets/icons/vendors/bankNGN.png'
import bankIDR from '../../app/assets/icons/vendors/bankIDR.png'
import bankMYR from '../../app/assets/icons/vendors/bankMYR.png'
import fast from '../../app/assets/icons/vendors/fast.png'
import imps from '../../app/assets/icons/vendors/imps.png'
import khipuPeru from '../../app/assets/icons/vendors/khipuPeru.png'
import khipuChile from '../../app/assets/icons/vendors/KhipuChile.png'
import mopesa from '../../app/assets/icons/vendors/mopesa.png'
import paloma from '../../app/assets/icons/vendors/paloma.png'
import pixbrl from '../../app/assets/icons/vendors/pixbrl.png'
import sepa from '../../app/assets/icons/vendors/sepa.png'
import spei from '../../app/assets/icons/vendors/spei.png'
import thai from '../../app/assets/icons/vendors/thai.png'
import upi from '../../app/assets/icons/vendors/upi.png'
import vietqr from '../../app/assets/icons/vendors/vietqr.png'
import eft from '../../app/assets/icons/vendors/eft.png'

import cardPopular from '../../app/assets/icons/popularVendors/cardPopular.png'
import bankPopular from '../../app/assets/icons/popularVendors/bankPopular.png'
import fastPopular from '../../app/assets/icons/popularVendors/fastPopular.png'
import impsPopular from '../../app/assets/icons/popularVendors/impsPopular.png'
import khiuPopular from '../../app/assets/icons/popularVendors/khiuPopular.png'
import mopesaPopular from '../../app/assets/icons/popularVendors/mopesaPopular.png'
import palommaPopular from '../../app/assets/icons/popularVendors/palommaPopular.png'
import pixPopular from '../../app/assets/icons/popularVendors/pixPopular.png'
import sepaPopular from '../../app/assets/icons/popularVendors/sepaPopular.png'
import speiPopular from '../../app/assets/icons/popularVendors/speiPopular.png'
import thaiPopular from '../../app/assets/icons/popularVendors/thaiPopular.png'
import upiPopular from '../../app/assets/icons/popularVendors/upiPopular.png'
import vietorqrPopular from '../../app/assets/icons/popularVendors/vietorqrPopular.png'
import instantEFTPopular from '../../app/assets/icons/popularVendors/instantEFTPopular.png'


export type CryptoName =
    | 'eth'
    | 'btc'
    | 'bch'
    | 'btg'
    | 'zec'
    | 'dash'
    | 'ltc'
    | 'usdt'
    | 'usdc'
    | 'qtum'
    | 'trx'
    | 'usdt_trc20'
    | 'bscusd'
    | 'bnb'
    | 'busd';

export type BlockchainNetwork = 'usdt' | 'usdt_trc20' | 'usdc' | 'bscusd' | 'busd' | 'usdt_bep20';

export type VendorsType = 'card'
    | 'upi'
    | 'fast'
    | 'united_arab_emirates_bank_transfer'
    | 'spei'
    | 'vietqr'
    | 'nigeria_bank_transfer'
    | 'pix'
    | 'khipu'
    | 'paloma_bank_transfer'
    | 'sepa_instant'
    | 'indonesia_bank_transfer'
    | 'mpesa'
    | 'thai_qr'
    | 'malaysia_bank_transfer'

export const SELECT_CRYPTO_CODES: Record<CryptoName, string> = {
    btc: 'BITCOIN',
    eth: 'ETHEREUM',
    usdt: 'USDT (ERC-20)',
    usdc: 'USDC (ERC-20)',
    ltc: 'LITECOIN',
    bch: 'BITCOIN CASH',
    zec: 'ZCASH',
    qtum: 'QTUM',
    btg: 'BITCOIN GOLD',
    dash: 'DASH',
    trx: 'TRON',
    usdt_trc20: 'USDT (TRC-20)',
    bscusd: 'USDT (BEP-20)',
    bnb: 'BINANCE COIN',
    busd: 'BUSD (BEP-20)'
};

export const CRYPTO_SHORT_NAME: Record<CryptoName, string> = {
    btc: 'BTC',
    eth: 'ETH',
    usdt: 'USDT',
    usdc: 'USDC',
    ltc: 'LTC',
    bch: 'BCH',
    zec: 'ZEC',
    qtum: 'QTUM',
    btg: 'BTG',
    dash: 'DASH',
    trx: 'TRX',
    usdt_trc20: 'USDT',
    bscusd: 'USDT',
    bnb: 'BNB',
    busd: 'BUSD'
};

export const CRYPTO_BUTTON_NAME: Record<CryptoName, string> = {
    btc: 'Bitcoin',
    eth: 'Ethereum',
    usdt: 'Tether USDt',
    usdc: 'USDC',
    ltc: 'Litecoin',
    bch: 'Bitcoin Cash',
    zec: 'Zcash',
    qtum: 'QTUM',
    btg: 'Bitcoin Gold',
    dash: 'DASH',
    trx: 'TRON',
    usdt_trc20: 'TRON TRC-20',
    bscusd: 'USDT BEP-20',
    bnb: 'Binance Coin',
    busd: 'Binance USD'
};


export enum CRYPTO_SHORT_NAMES {
    BTC = 'BTC',
    ETH = 'ETH',
    USDT = 'USDT',
    USDC = 'USDC',
    LTC = 'LTC',
    BCH = 'BCH',
    ZEC = 'ZEC',
    QTUM = 'QTUM',
    BTG = 'BTG',
    DASH = 'DASH',
    TRX = 'TRX',
    USDT_TRC20 = 'USDT_TRC20',
    BSCUSD = 'BSCUSD',
    BNB = 'BNB',
    BUSD = 'BUSD'
}


export const CRYPTO_VALUES_TO_DIVIDE: Record<CRYPTO_SHORT_NAMES, number> = {
    BTC: 100000000,
    ETH: 1000000000000000000,
    USDT: 1000000,
    USDC: 1000000,
    LTC: 100000000,
    BCH: 100000000,
    ZEC: 100000000,
    QTUM: 100000000,
    BTG: 100000000,
    DASH: 100000000,
    TRX: 1000000,
    USDT_TRC20: 1000000,
    BNB: 1000000000000000000,
    BSCUSD: 1000000000000000000,
    BUSD: 1000000000000000000
};

export const MINIMUM_TRANSACTION_AMOUNT: Record<CryptoName, number> = {
    btc: 0.001,
    eth: 0.01,
    usdt: 15,
    usdc: 15,
    ltc: 0.01,
    bch: 0.001,
    zec: 0.001,
    qtum: 0.001,
    btg: 0.001,
    dash: 0.001,
    trx: 15,
    usdt_trc20: 15,
    bnb: 0.1,
    bscusd: 15,
    busd: 15
};


export const BLOCKCHAIN_NETWORK: Record<BlockchainNetwork, string> = {
    usdt: 'ERC-20',
    usdt_trc20: 'TRC-20',
    usdt_bep20: 'BEP-20',
    usdc: 'ERC-20',
    bscusd: 'BEP-20',
    busd: 'BEP-20'
};

export type CryptoIcons = {
    [key: string]: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const CRYPTO_ICONS: CryptoIcons = {
    [CRYPTO_SHORT_NAMES.BTC]: BtcIcon,
    [CRYPTO_SHORT_NAMES.ETH]: EthIcon,
    [CRYPTO_SHORT_NAMES.USDT]: UsdtIcon,
    [CRYPTO_SHORT_NAMES.USDT_TRC20]: TronUsdtIcon,
    [CRYPTO_SHORT_NAMES.USDC]: UsdcIcon,
    [CRYPTO_SHORT_NAMES.LTC]: LtsIcon,
    [CRYPTO_SHORT_NAMES.TRX]: TronIcon,
    [CRYPTO_SHORT_NAMES.BCH]: BchIcon,
    [CRYPTO_SHORT_NAMES.ZEC]: ZecIcon,
    [CRYPTO_SHORT_NAMES.QTUM]: QtumIcon,
    [CRYPTO_SHORT_NAMES.BTG]: BtgIcon,
    [CRYPTO_SHORT_NAMES.DASH]: DashIcon,
    // [CRYPTO_SHORT_NAMES.BSC]: BscIcon,
    [CRYPTO_SHORT_NAMES.BSCUSD]: BscUsdIcon,
    [CRYPTO_SHORT_NAMES.BUSD]: BusdIcon,
    [CRYPTO_SHORT_NAMES.BNB]: BnbIcon
};

export const VENDORS_ICONS = {
    'card': card,
    'upi': upi,
    'fast': fast,
    'united_arab_emirates_bank_transfer': bankUAE,
    'spei': spei,
    'vietqr': vietqr,
    'nigeria_bank_transfer': bankNGN,
    'pix': pixbrl,
    'khipu': khipuPeru,
    'paloma_bank_transfer': paloma,
    'sepa_instant': sepa,
    'indonesia_bank_transfer': bankIDR,
    'mpesa': mopesa,
    'instant_eft': eft,
    'thai_qr': thai,
    'malaysia_bank_transfer': bankMYR

};

export const VENDORS_ICONS_POPULAR = {
    'card': cardPopular,
    'upi': upiPopular,
    'fast': fastPopular,
    'united_arab_emirates_bank_transfer': bankPopular,
    'spei': speiPopular,
    'vietqr': vietorqrPopular,
    'nigeria_bank_transfer': bankPopular,
    'pix': pixPopular,
    'khipu': khiuPopular,
    'paloma_bank_transfer': palommaPopular,
    'sepa_instant': sepaPopular,
    'indonesia_bank_transfer': bankPopular,
    'mpesa': mopesaPopular,
    'instant_eft': instantEFTPopular,
    'thai_qr': thaiPopular,
    'malaysia_bank_transfer': bankPopular

};

export const valueVendorsArray = [
    'card',
    'upi',
    'fast',
    'united_arab_emirates_bank_transfer',
    'spei',
    'vietqr',
    'nigeria_bank_transfer',
    'pix',
    'khipu',
    'paloma_bank_transfer',
    'sepa_instant',
    'indonesia_bank_transfer',
    'mpesa',
    'instant_eft',
    'thai_qr',
    'malaysia_bank_transfer']

export const filterSelectedArrayOnVendors = (value) => {
    return valueVendorsArray.includes(value)
}

// export const

export type ProcessingResponce = {
    blockchains: Blockchain[]
}

export type Blockchain = {
    code: string
    unit: string
    tokens: Token[]
}

export type Token = {
    code: string
    unit: string
}

export type ResponseVendorType = {
    vendors: Array<{
        type: string
        name: string
        codes: Array<string>
        methods: Array<{
            type: string
            currency: string
            rate: number
            link: {
                self: string
                related: Array<{
                    href: string
                    title: string
                    name: string
                }>
            }
        }>
    }>
}


export const liftCode = (currencies) => {
    const result = [];
    const stack = [...currencies];

    while (stack.length > 0) {
        const current = stack.pop();
        result.push(current);

        if (current.tokens && current.tokens.length > 0) {
            stack.push(...current.tokens);
        }
    }

    return result;
};


export const makeSeletOptions = (currencies: Blockchain[]) => {
    const coins = liftCode(currencies).filter(el => el.code !== "bsc")
    return coins.map((el) => {
        return {
            ...el,
            img: CRYPTO_ICONS[el.code.toUpperCase()],
            name: "cryptoGroup",
            value: el.code,
            fullValue: SELECT_CRYPTO_CODES[el.code],
            // type:
        }
    })
}

export const findDataBlockChain = (currencies: Blockchain[], code: string) => {
    if (currencies) {
        const newCurrencies = liftCode(currencies)
        return newCurrencies.find(obj => obj.code === code)
    }
}

export const filteredOnClientsCode = (currencies: Blockchain[], clientsCode: string[]) => {
    const createdOptions = makeSeletOptions(currencies)
    return createdOptions.filter(obj => clientsCode.includes(obj.code))

}

export const onClientsArr = (currencies: Blockchain[], clientsCode: string[]) => {
    const coins = filteredOnClientsCode(currencies, clientsCode)
    const noUsdtTrue = coins.find(el => el.code === "usdt_trc20" || el.code === "bscusd")
    const usdtTrue = coins.find(el => el.code === "usdt")
    if (noUsdtTrue && !usdtTrue) {
        coins.push(
            {
                code: 'usdt',
                img: CRYPTO_ICONS['USDT'],
                name: "cryptoGroup",
                value: 'usdt',
                fullValue: SELECT_CRYPTO_CODES['USDT']
            }
        )
    }
    return coins.filter(el => el.code !== "usdt_trc20" && el.code !== "bscusd")
}

export const networkFilter = (currencies: Blockchain[]) => {
    const createdOptions = makeSeletOptions(currencies)
    const noUsdtTrue = createdOptions.find(el => el.code === "usdt_trc20" || el.code === "bscusd")
    const usdtTrue = createdOptions.find(el => el.code === "usdt")
    if (noUsdtTrue && !usdtTrue) {
        createdOptions.push(
            {
                code: 'usdt',
                img: CRYPTO_ICONS['USDT'],
                name: "cryptoGroup",
                value: 'usdt',
                fullValue: SELECT_CRYPTO_CODES['USDT']
            }
        )
    }
    return createdOptions.filter(el => el.code !== "usdt_trc20" && el.code !== "bscusd")

}

export const changeValueForButton = (coin) => {
    return (CRYPTO_BUTTON_NAME[coin.code])
}

export type TetherNetworkType = {
    id: string
    name: string
    value: string
    img: FunctionComponent<React.SVGProps<SVGSVGElement>>
    fullValue: string
}

export const tetherNetwork: TetherNetworkType[] = [
    {
        id: "11",
        name: "twoGroup",
        value: "usdt_trc20",
        img: CRYPTO_ICONS[CRYPTO_SHORT_NAME.trx],
        fullValue: "USDT (TRC-20)"
    }, {
        id: "12",
        name: "twoGroup",
        value: "usdt",
        img: CRYPTO_ICONS[CRYPTO_SHORT_NAME.eth],
        fullValue: "USDT (ERC-20)"
    }, {
        id: "13",
        name: "twoGroup",
        value: "bscusd",
        img: CRYPTO_ICONS[CRYPTO_SHORT_NAME.bnb],
        fullValue: "USDT (BEP-20)"
    }]


export const vendors = [
    {
        id: "1",
        name: "cryptoGroup",
        value: "card",
        img: VENDORS_ICONS["card"],
        fullValue: ""
    },
    {
        id: "2",
        name: "cryptoGroup",
        value: 'upi',
        img: VENDORS_ICONS["upi"],
        fullValue: ""
    },
    // {
    //     id: "3",
    //     name: "vendorsGroup",
    //     value: 'fast',
    //     img: VENDORS_ICONS["card"],
    //     fullValue: ""
    // },
    {
        id: "4",
        name: "cryptoGroup",
        value: 'fast',
        img: VENDORS_ICONS['fast'],
        fullValue: ""
    },
    {
        id: "5",
        name: "cryptoGroup",
        value: 'united_arab_emirates_bank_transfer',
        img: VENDORS_ICONS['united_arab_emirates_bank_transfer'],
        fullValue: ""
    },
    {
        id: "6",
        name: "cryptoGroup",
        value: 'spei',
        img: VENDORS_ICONS['spei'],
        fullValue: ""
    },
    {
        id: "7",
        name: "cryptoGroup",
        value: 'vietqr',
        img: VENDORS_ICONS['vietqr'],
        fullValue: ""
    },
    {
        id: "8",
        name: "cryptoGroup",
        value: 'nigeria_bank_transfer',
        img: VENDORS_ICONS['nigeria_bank_transfer'],
        fullValue: ""
    },
    {
        id: "9",
        name: "cryptoGroup",
        value: 'pix',
        img: VENDORS_ICONS['pix'],
        fullValue: ""
    },
    {
        id: "10",
        name: "cryptoGroup",
        value: 'khipu',
        img: VENDORS_ICONS['khipu'],
        fullValue: ""
    },
    {
        id: "11",
        name: "cryptoGroup",
        value: 'paloma_bank_transfer',
        img: VENDORS_ICONS['paloma_bank_transfer'],
        fullValue: ""
    },
    // {
    //     id: "12",
    //     name: "vendorsGroup",
    //     value: "card",
    //     img: VENDORS_ICONS["card"],
    //     fullValue: ""
    // },
    {
        id: "13",
        name: "cryptoGroup",
        value: 'sepa_instant',
        img: VENDORS_ICONS['sepa_instant'],
        fullValue: ""
    },
    {
        id: "14",
        name: "cryptoGroup",
        value: 'indonesia_bank_transfer',
        img: VENDORS_ICONS['indonesia_bank_transfer'],
        fullValue: ""
    },
    {
        id: "15",
        name: "cryptoGroup",
        value: 'mpesa',
        img: VENDORS_ICONS['mpesa'],
        fullValue: ""
    }, {
        id: "16",
        name: "cryptoGroup",
        value: 'instant_eft',
        img: VENDORS_ICONS['instant_eft'],
        fullValue: ""
    },
    {
        id: "17",
        name: "cryptoGroup",
        value: 'thai_qr',
        img: VENDORS_ICONS['thai_qr'],
        fullValue: ""
    },
    {
        id: "18",
        name: "cryptoGroup",
        value: 'malaysia_bank_transfer',
        img: VENDORS_ICONS['malaysia_bank_transfer'],
        fullValue: ""
    }

]

const test = {
    vendors: [
        {
            "type": "ONRAMP",
            "name": "switchere",
            "codes": [
                "usdt_trc20",
                "usdt",
                "btc",
                "eth",
                "bch",
                "ltc",
                "trx"
            ],
            "methods": [
                {
                    "type": "card",
                    "currency": "EUR",
                    "rate": 0.5,
                    "link": {
                        "self": "https://onramp.preprod.xamax.gpd.onl/onramp/xamaAMUAlNM9SwQxEMbx7zL1DGSSbJJNJ2ph4QuHrUWSyaCCWHg2wn13ybreC2hxbZo8f_jN35BVZy3aw5mgjymvhp_wwHadMfjiP34zR3Yx8RSZZ4MnWvP0-7DXnOHzQ35GHn1wEr9DeOvb53dZVl3dbK4vHwHh_m5zcfsw5pWvETi40UHEPhxwPZLaJy8cGpnCQl6sUjJeqbYadfY-lGYAl1NaIlJINiSE1-0LZJBmu1RbSVkc-SSdipuFbDRW1bWqwcHuOwAA___bGmhFo72ZgSZLfpvCkQiH4sJW02PR5Y4DBE-4m43ra0zR7RXAzUZ6m2IB9GFtRxRtWVUFpH28eiknZFmqBlYC/eur/sw.json",
                        "related": [
                            {
                                "href": "https://onramp.preprod.xamax.gpd.onl/onramp/xamaAMUAlNM9SwQxEMbx7zL1DGSSbJJNJ2ph4QuHrUWSyaCCWHg2wn13ybreC2hxbZo8f_jN35BVZy3aw5mgjymvhp_wwHadMfjiP34zR3Yx8RSZZ4MnWvP0-7DXnOHzQ35GHn1wEr9DeOvb53dZVl3dbK4vHwHh_m5zcfsw5pWvETi40UHEPhxwPZLaJy8cGpnCQl6sUjJeqbYadfY-lGYAl1NaIlJINiSE1-0LZJBmu1RbSVkc-SSdipuFbDRW1bWqwcHuOwAA___bGmhFo72ZgSZLfpvCkQiH4sJW02PR5Y4DBE-4m43ra0zR7RXAzUZ6m2IB9GFtRxRtWVUFpH28eiknZFmqBlYC/eur/sw.html",
                                "title": "",
                                "name": "onramp_link"
                            }
                        ]
                    }
                },
                {
                    "type": "card",
                    "currency": "USD",
                    "rate": 0.7,
                    "link": {
                        "self": "https://onramp.preprod.xamax.gpd.onl/onramp/xamaAMUAlNM9SwQxEMbx7zL1DGSSbJJNJ2ph4QuHrUWSyaCCWHg2wn13ybreC2hxbZo8f_jN35BVZy3aw5mgjymvhp_wwHadMfjiP34zR3Yx8RSZZ4MnWvP0-7DXnOHzQ35GHn1wEr9DeOvb53dZVl3dbK4vHwHh_m5zcfsw5pWvETi40UHEPhxwPZLaJy8cGpnCQl6sUjJeqbYadfY-lGYAl1NaIlJINiSE1-0LZJBmu1RbSVkc-SSdipuFbDRW1bWqwcHuOwAA___bGmhFo72ZgSZLfpvCkQiH4sJW02PR5Y4DBE-4m43ra0zR7RXAzUZ6m2IB9GFtRxRtWVUFpH28eiknZFmqBlYC/usd/sw.json",
                        "related": [
                            {
                                "href": "https://onramp.preprod.xamax.gpd.onl/onramp/xamaAMUAlNM9SwQxEMbx7zL1DGSSbJJNJ2ph4QuHrUWSyaCCWHg2wn13ybreC2hxbZo8f_jN35BVZy3aw5mgjymvhp_wwHadMfjiP34zR3Yx8RSZZ4MnWvP0-7DXnOHzQ35GHn1wEr9DeOvb53dZVl3dbK4vHwHh_m5zcfsw5pWvETi40UHEPhxwPZLaJy8cGpnCQl6sUjJeqbYadfY-lGYAl1NaIlJINiSE1-0LZJBmu1RbSVkc-SSdipuFbDRW1bWqwcHuOwAA___bGmhFo72ZgSZLfpvCkQiH4sJW02PR5Y4DBE-4m43ra0zR7RXAzUZ6m2IB9GFtRxRtWVUFpH28eiknZFmqBlYC/usd/sw.html",
                                "title": "",
                                "name": "onramp_link"
                            }
                        ]
                    }
                }
            ]
        },

    ]
}


export const receivePopularPaymentVendor = (vendorsResponse: ResponseVendorType | undefined) => {
    if (vendorsResponse) {
        const newArr = vendorsResponse.vendors.sort((a, b) => b.methods[0].rate - a.methods[0].rate)
        if (newArr.length > 0) {
            return newArr[0].methods[0]
        } else {
            return false
        }
    }
}


export const receiveButtonsPaymentVendors = (vendorsResponse) => {

    if (!vendorsResponse) {
        return []
    }
    const newArr = vendorsResponse.vendors.sort((a, b) => b.methods[0].rate - a.methods[0].rate)
        .map(el => el.methods.map(el => el.type)).reduce((acc, curr) => acc.concat(curr), [])
    const uniqArr = Array.from(new Set(newArr))
    const buttonArr = vendors.filter(el => uniqArr.includes(el.value))
        .sort((a, b) => uniqArr.findIndex((el) => el == a.value) - uniqArr.findIndex((el) => el == b.value))
    return buttonArr

}

export const filterVendorsForCode = (vendors, value) => {
    const dataVendor = {}
    const vendor = vendors.vendors.sort((a, b) => b.methods[0].rate - a.methods[0].rate).find(el => el.methods.find(el => el.type.includes(value)))
    dataVendor['vendor'] = vendor.name
    dataVendor['method'] = value
    dataVendor['code'] = vendor.codes[0]
    return dataVendor

}

