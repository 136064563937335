// import {decodingByJWT} from "shared/utils/decompress";

import { baseUrl } from 'shared/api/url';
import { s } from 'vitest/dist/reporters-1evA5lom';

const pathname = location.pathname.slice(1);
// const decodingPath = decodingByJWT(pathname)

const params = {
  baseURL: baseUrl,
  options: {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${pathname}`
    }
  }
};

export type InvoiceErrorType = {
  code: number;
  message: string;
  details?: Array<{
    '@type': string;
    crypto: {
      code: string;
      minValue: string;
    };
  }>;
};

export interface GetGeoRestrictions {
  allowPaymentLink: boolean;
}

export const paymentLinkAPI = {
  async getTransactionIncoming(id) {
    const response = await fetch(`${params.baseURL}/v1/transaction/incoming/${id}`, {
      headers: params.options.headers
    });

    if (response.status === 200) {
      const result = await response.json();
      // console.log(result)
      return result;
    } else {
      const result = await response.json();
      return Promise.reject(result);
    }
    // if (response.status === 404) {
    //     const result = await response.json()
    //     console.log(result.message)
    // }
    // if (response.status === 409) {
    //     const result = await response.json()
    //     console.log(result.message)
    // } else {
    //     const message = 'Error:' + response.status
    //     throw new Error(message)
    // }
  },

  async getConfigProcessing() {
    try {
      const response = await fetch(`${params.baseURL}/.config/processing.json`);

      if (response.status === 200) {
        let result = await response.json();
        return result;
      } else {
        const message = 'Error:' + response.status;
        throw new Error(message);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async createInvoice(data, checkBox?) {
    const response = await fetch(`${params.baseURL}/v1/transaction/invoice`, {
      headers: params.options.headers,
      method: 'POST',
      body: JSON.stringify(data)
    });

    if (response.ok) {
      let result = await response.json();
      return result;
    } else {
      let result = await response.json();
      if (result.code === 3) {
        if (checkBox) {
          const amount = result.details.find((el) => Object.keys(el).includes('fiat')).fiat.amount;
          throw new Error(
            JSON.stringify({
              resCode: result.code,
              message: result.message,
              value: amount
            })
          );
        } else {
          const amount = result.details.find((el) => Object.keys(el).includes('crypto')).crypto.minValue;
          const code = result.details.find((el) => Object.keys(el).includes('crypto')).crypto.code;
          throw new Error(
            JSON.stringify({
              resCode: result.code,
              message: result.message,
              code: code,
              value: amount
            })
          );
        }
      }
      if (result.code === 6) {
        throw new Error(
          JSON.stringify({
            resCode: result.code,
            message: result.message
          })
        );
      }
    }
  },

  async exchangeRate(data: any, controller?: AbortController) {
    try {
      const response = await fetch(`${params.baseURL}/v1/exchangerate/estimate`, {
        headers: params.options.headers,
        method: 'POST',
        body: JSON.stringify(data),
        signal: controller?.signal
      });

      if (response.status === 200) {
        let result = await response.json();
        return result;
      } else {
        const message = 'Error:' + response.status;
        throw new Error(message);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async receiveVendors(data: any) {
    try {
      const response = await fetch(`${params.baseURL}/v1/payment-link/vendors`, {
        headers: params.options.headers,
        method: 'POST',
        body: JSON.stringify(data)
      });

      if (response.status === 200) {
        let result = await response.json();
        return result;
      } else {
        const message = 'Error:' + response.status;
        throw new Error(message);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getGeolocation() {
    try {
      const response = await fetch(`https://geoip.xamax.io/`);

      if (response.status === 200) {
        let result = await response.json();
        return result;
      } else {
        const message = 'Error:' + response.status;
        throw new Error(message);
      }
    } catch (error) {
      console.log(error);
    }
  },
  
  async getRestrictions(geo: string) {
    try {
      const response = await fetch(`${params.baseURL}/v1/merchant/restrictions`, {
        headers: params.options.headers,
        method: 'POST',
        body: JSON.stringify({ geo })
      });

      if (response.status === 200) {
        const result: GetGeoRestrictions = response.json();
        return result;
      } else {
        const message = 'Error:' + response.status;
        throw new Error(message);
      }
    } catch (error) {
      console.log(error);
    }
  }
};
