/* eslint-disable @typescript-eslint/no-unsafe-argument */
import ReactDOM from 'react-dom/client';
import "app/styles/main.scss"
import App from 'app/App';
import {Web3ModalProvider} from "web3Provider";


var process = {
    env: {}
}

window.process = process

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Web3ModalProvider>
        <App/>
    </Web3ModalProvider>
);
