import {useState} from "react";

export const useKeyboardBacklight = () => {
    const [key, setKey] = useState("")

    window.addEventListener("keyup", (e) => {
        setKey(e.key)
    })


    return key
}
