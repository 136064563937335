import { FormEvent, useEffect, useState } from 'react';
import { BaseError, useWriteContract, useWaitForTransactionReceipt, useAccount, useDisconnect } from 'wagmi';
import { bsc, bscTestnet } from 'viem/chains';
import { bscAbi } from 'shared/utils/walletConnect/abi';
import { findDataBlockChain } from 'shared/utils/currency';
import { paymentLinkAPI } from 'shared/api/api';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useWeb3ModalState } from '@web3modal/wagmi/react';
import styles from './ButtonConnectToWalletBSCUSDBUSD.module.scss';
import copy from 'app/assets/icons/copy.svg';
import { useSendTransaction } from 'wagmi';
import { parseEther } from 'viem';
import { baseUrl } from 'shared/api/url';
import { NETWORKS } from 'shared/utils/walletConnect/network';

type ButtonConnectToWalletProps = {
  code: string;
  amount: string;
  wallet: string;
  copyBuffer: (data: string) => void;
  setHash: (hash: string) => void;
};

export const ButtonConnectToWalletBSCUSDBUSD = (props: ButtonConnectToWalletProps) => {
  const { currency, code, amount, wallet, setHash, copyBuffer } = props;
  const [cryptoCurrency, setCryptoCurrency] = useState();
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const [disabled, setDisabled] = useState(false);
  const [warning, setWarning] = useState('');
  const { address, isConnected, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const { selectedNetworkId } = useWeb3ModalState();

  console.log(error);

  useEffect(() => {
    paymentLinkAPI.getConfigProcessing().then((res) => setCryptoCurrency(findDataBlockChain(res.blockchains, code)));
  }, []);

  async function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setDisabled(true);
    if (baseUrl === 'https://api.sandbox.xamax.io' || baseUrl === 'https://api.preprod.xamax.gpd.onl') {
      if (selectedNetworkId !== 97) {
        setWarning(
          `Please, change network in Metamask ${
            NETWORKS[String(selectedNetworkId)] ? NETWORKS[String(selectedNetworkId)] : 'UNKNOWN NETWORK'
          } -> BIP-20 TEST`
        );
        return;
      }
      setWarning('');
      const res = await writeContract({
        chainId: bscTestnet.id,
        address: cryptoCurrency.address,
        functionName: 'transfer',
        abi: bscAbi,
        args: [ , BigInt(amount)]
      });
      console.log(res);
    } else {
      if (selectedNetworkId !== 56) {
        setWarning(
          `Please, change network in Metamask ${
            NETWORKS[String(selectedNetworkId)] ? NETWORKS[String(selectedNetworkId)] : 'UNKNOWN NETWORK'
          } -> BIP-20`
        );
        return;
      }
      setWarning('');
      writeContract({
        chainId: bsc.id,
        address: cryptoCurrency.address,
        functionName: 'transfer',
        abi: bscAbi,
        args: [wallet, BigInt(amount)]
      });
    }
  }

  useEffect(() => {
    setHash(hash);
  }, [hash]);

  useEffect(() => {
    if (isDisconnected || !isConnected) {
      setWarning('');
    }
  }, [isDisconnected, isConnected]);

  useEffect(() => {
    if ((error && selectedNetworkId) || (warning && selectedNetworkId)) {
      setDisabled(false);
    }
  }, [error, selectedNetworkId, warning]);

  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash
  });

  const openModal = () => {
    open();
    setWarning('');
  };

  return (
    <div className="container">
      <div className="stack">
        <form className={styles.connect} onSubmit={submit}>
          {(isDisconnected || !isConnected) && (
            <button onClick={openModal} className={styles.connect__button} type="button">
              Wallet Connect
            </button>
          )}
          {isConnected && !hash && (
            <button className={styles.connect__button} type="submit" disabled={disabled}>
              PAY
            </button>
          )}
          {/*<button disabled={isPending} type="submit">*/}
          {/*    {isPending ? 'Confirming...' : 'Transfer'} /!* Изменяем текст кнопки на "Transfer" *!/*/}
          {/*</button>*/}
        </form>
        <div className={styles.about}>
          {hash && (
            <div className={styles.hash}>
              <p className={styles.hash__text}>{`Transaction Hash: ${hash.slice(0, 5)}...${hash.slice(50)}`}</p>
              <button className={styles.hash__buffer} onClick={() => copyBuffer(hash)}>
                <img className={styles.hash__copy} src={copy} alt="" />
              </button>
            </div>
          )}
          {address && <div className={styles.about__text}>Wallet : {address}</div>}
          {isConnected && !hash && (
            <button className={styles.about__button} onClick={() => disconnect()}>
              Disconnected
            </button>
          )}
          {isConfirming && <div className={styles.about__text}>Waiting for confirmation...</div>}
          {isConfirmed && <div className={styles.about__text}>Transaction confirmed.</div>}
          {error && !error.message.includes('not connected') && isConnected && (
            <div className={styles.error}>
              <span className={styles.error__symbol}>!</span>
              <span className={styles.error__text}>Error: {(error as BaseError).shortMessage || error.message}</span>
            </div>
          )}
          {warning && (
            <h4 className={styles.warning}>
              <span className={styles.warning__symbol}>!</span>
              <span className={styles.warning__text}>{warning}</span>
            </h4>
          )}
        </div>
      </div>
    </div>
  );
};
