import {FC, memo} from "react";
import styles from "./Footer.module.scss"
import amountPay from "app/assets/icons/amount__pay-methods.svg";
import logo from "app/assets/images/logo.png";

export const Footer: FC = memo(() => {

    return (
        <div className={styles.footer}>
            <img src={amountPay} alt=""/>
            <div className={styles.footer__made}>
                <h5 className={styles.footer__subtitle}>Cryptopowered by</h5>
                <img src={logo} alt=""/>
            </div>
        </div>
    )
})
