import styles from 'pages/PaymentPage/ui/Accordion/Accordion.module.scss';
import connectWrapperStyles from './ConnectWrapper.module.scss';
import walletIcon from 'app/assets/icons/wallet.svg';
import { ButtonConnect } from 'pages/PaymentPage/ui/ButtonConnect/ButtonConnect';
import hashImg from 'app/assets/icons/hash.svg';
import { type InvoiceResponseType } from 'shared/types/types';
import binanceIconSrc from 'app/assets/icons/markets/binance-logo.svg';
import bybitIconSrc from 'app/assets/icons/markets/bybit.svg';
import tronLinkIconSrc from 'app/assets/icons/markets/tronlink.png';
import trustIconSrc from 'app/assets/icons/markets/trust-wallet.svg';

interface ConnectWrapperType {
  openedAccordions: string[];
  handleSetOpenedAccordions: (accordion: string) => void;
  dateInvoiceResponse: InvoiceResponseType;
  copyBuffer: (text: string) => void;
  setHash: any;
  hash: any;
  dataUser: any;
  hashAutoInput: any;
  handlerChangeAutoInput: any;
  handleKeyPressAuto: any;
  autoSetInputValue: any;
}

export const ConnectWrapper = (props: ConnectWrapperType) => {
  const { openedAccordions, handleSetOpenedAccordions, dateInvoiceResponse, copyBuffer, setHash, hash } = props;
  const { dataUser, hashAutoInput, handlerChangeAutoInput, handleKeyPressAuto, autoSetInputValue } = props;

  return (
    <li className={styles.accordion__item}>
      <div
        className={connectWrapperStyles.connectWrapperHeader}
        onClick={() => {
          handleSetOpenedAccordions('2');
        }}
      >
        <div className={connectWrapperStyles.connectWrapperHeaderLeft}>
          <img className={styles.accordion__img} src={walletIcon} alt="Wallet" />
          <p className={styles.accordion__text}>Wallet</p>
          <div className={connectWrapperStyles.cryptoMarkets}>
            <img className={connectWrapperStyles.icon} src={binanceIconSrc} alt="Binance" />
            <img className={connectWrapperStyles.icon} src={bybitIconSrc} alt="ByBit" />
            <img className={connectWrapperStyles.icon} src={tronLinkIconSrc} alt="TronLink" />
            <img className={connectWrapperStyles.icon} src={trustIconSrc} alt="Trust" />
          </div>
        </div>
        <button className={styles.accordion__button}>Click</button>
      </div>
      <div className={`${styles.accordion__collapsed} ${openedAccordions.includes('2') ? styles.accordion__open : ''}`}>
        <ButtonConnect
          code={dateInvoiceResponse.codeRequested}
          amount={dateInvoiceResponse.amountRequired}
          amountUnit={dateInvoiceResponse.amountRequiredUnit}
          wallet={dateInvoiceResponse.walletAddress}
          copyBuffer={copyBuffer}
          setHash={setHash}
        />
        {dataUser.type === 'invoice_type_user' && (
          <div className={styles.accordion__user}>
            <input
              className={`${styles.accordion__connectHash} ${hash ? styles.accordion__connectHashLight : ''}`}
              placeholder={'Paste transaction hash here...'}
              type="text"
              value={hashAutoInput || hash}
              onChange={handlerChangeAutoInput}
              onKeyPress={handleKeyPressAuto}
            />
            <button className={styles.accordion__submit} onClick={autoSetInputValue}>
              <img src={hashImg} alt="" />
            </button>
          </div>
        )}
      </div>
    </li>
  );
};
